:root {
    --text-01: #ffffff;
    --light-01: #F9F9F9;
    --light-02: #FFFFFF;
    --brand-01: #88e61d;
    --brand-02: #2c97f0;
    --card-hover: 0px 4px 24px rgba(0, 0, 0, 0.15);
    --card-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    --hover-timing: all 0.2s ease;
    --nav-card-size: 240px;
    





    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    scroll-behavior: smooth;


}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    list-style: none;
}

body {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    transition: var(--hover-timing);

    background: var(--light-01);

    width: 100vw;
    overflow-x: hidden;

}




.shop-pets {
    width: 98rem;
}
.banner {
    background: var(--brand-01);
    color: var(--light-01);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    padding: 8px 24px;
    display: flex;
    align-items: center;
    justify-content: center;

}


/* Add styles for each color class */

/* Add more color classes for additional cards if needed */









.card-large,
.card-med {
    /* flex: 1; */
    position: relative;
    display: flex;
    flex-direction: column;
    transition: var(--hover-timing);
    cursor: pointer;
    box-shadow: var(--card-shadow);
    border-radius: 12px;
    overflow: hidden;
    height: 22rem;
    width: 20rem;
    text-align: center;
}

/* .card-light {
    background-color: var(--light-01);
    color: var(--text-01);
} */




.card-large:hover {
    box-shadow: var(--card-hover);
    transform: scale(1.01);
}

.card-med:hover .card-image {
    transform: scale(1.01);
}


.card-image {
    width: 100%;
    /*    height: 90%;*/
    aspect-ratio: 1/1;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--hover-timing);
}










.card-large>.card-image {
    aspect-ratio: 3/2;
}

.card-image>img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: contain;



}

.card-large a {
    text-decoration: none;
    font-weight: 600;
}

.card-large>.card-image>a {
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
    font-size: 24px;
    line-height: 1.2;
}




.card-large>ul {
    /*    padding: 16px 0 24px;*/
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    flex: 1;
    /*    border: 1px solid blue;*/

    color: var(--text-01);

    padding: 0 40px 40px;
    font-size: 28px;
    font-weight: 600;
    line-height: 1.5;
}



.card-large>ul>li a {
    line-height: 32px;
    font-size: 14px;
    /*    border: 1px solid red;*/
}




.card-large#sup-service,
.card-med#sup-service>.card-image {
    background: linear-gradient(45deg, #37d199 0%, #32dbc7 100%);
}

.card-large#sup-stop,
.card-med#sup-stop>.card-image {
    background: linear-gradient(45deg, #ecd0ff 0%, #f39aff 100%);
}

.card-large#sup-sop,
.card-med#sup-sop>.card-image {
    background: linear-gradient(45deg, #10e429 0%, #e3f1b6 100%);
}

.card-large#sup-heat,
.card-med#sup-heat>.card-image {
    background: linear-gradient(45deg, #ff1198 0%, #dbbed5 100%);
}

.card-large#sup-at,
.card-med#sup-at>.card-image {
    background: linear-gradient(45deg, #75ce63 0%, #c7fac2 100%);
}

.card-large#sup-plumbing,
.card-med#sup-plumbing>.card-image {
    background: linear-gradient(45deg, #ef73eb 0%, #f493e5 100%);
}




/* button.btn-outline {
    color: var(--light-01);
    border-color: var(--light-01);
} */

.store-banner-button {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    padding: 12px 24px;
    border-radius: 48px;
    display: flex;
    flex-direction: row;
    grid-gap: 8px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}







.card-large#sup-cat,
.card-med#sup-cat>.card-image {
    background: linear-gradient(45deg, #F6AE6C 0%, #ECBD73 100%);
}


.card-large#sup-bird,
.card-med#sup-bird>.card-image {
    background: linear-gradient(45deg, #EFEFEF 0%, #F2F2F2 100%);
}


.card-large#sup-fish,
.card-med#sup-fish>.card-image {
    background: linear-gradient(45deg, #1E4782 0%, #709DDF 100%);
}


.card-large.card-dark a,
.card-large.card-dark>ul {
    color: var(--text-01);
}

.card-large.card-light a,
.card-large.card-light>ul {
    color: var(--light-01);
}





/* button {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    padding: 12px 24px;
    border-radius: 48px;
    display: flex;
    flex-direction: row;
    grid-gap: 8px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
} */



.btn-outline-light-cart {
    color: var(--light-01);
    background: none;
    color: var(--light-01);
    border: 2px solid var(--light-01);
}

.btn-outline-dark-cart {
    color: var(--text-01);
    background: none;
    color: black;
    border: 2px solid black;
}



.btn-outline-dark-cart:hover {
    background: black;
    border: 2px solid black;
    color: rgb(255, 255, 255);
}

.btn-outline-light-cart:hover {
    background: var(--light-01);
    border: 2px solid var(--light-01);
    color: black;
}





section {
    position: relative;
    width: 100%;
    padding: 0 var(--pg-margin);
}




section.hero {
    /*    width: 100%;*/
    height: auto;
    background: var(--brand-02) url('https://ouch-cdn2.icons8.com/hxxz5Qr551KY1597yq-mz9zWRTkAT-cob5eZ8UreBBo/rs:fit:368:338/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9wbmcvNzcy/L2UxYjU4YWUwLTc3/YjQtNGQ1OC05NjJl/LWUzODQ1Y2IyYzBi/Ny5wbmc.png') no-repeat center right;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    grid-gap: 48px;
    display: inline-flex;

}




section.hero h1 {
    font-size: var(--hero-text);
    font-weight: 600;
    line-height: 1.2;
    width: 40%;

    color: var(--text-01);
}

.card-med {
    width: var(--nav-card-size);
    height: auto;
    /*    border: 1px solid green;*/
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.card-med>a {
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    padding: 12px 16px 0px;
}

.card-med>a>span {
    width: 100%;
    /*    border: 1px solid blue;*/
}

.card-med>a>span:nth-of-type(1) {
    width: 100%;
    /*    border: 1px solid blue;*/
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
}

.card-med>a>span:nth-of-type(2) {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 6px;
}

.card-med>a>span:nth-of-type(2)>span {
    font-size: 18px;
}

#serv-groom>.card-image {
    background: linear-gradient(45deg, #45828C 0%, #A7D4D8 100%);
}

#serv-board>.card-image {
    background: linear-gradient(45deg, #EDDAA9 0%, #B87D93 100%);
}

.card-med:hover .card-image {
    box-shadow: var(--card-hover);
}


.card-med>.card-image {
    box-shadow: var(--card-shadow);
}

.card-med>.card-image>img {
    width: 80%;
    height: 80%;
    /*    border: 1px solid red;*/
}

section:not(.hero) {
    padding: calc(var(--pg-margin)/2) var(--pg-margin);
}

section h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    color: black;
    margin-bottom: 32px;
    /*    padding-bottom: 24px;*/
}


.card-wide {
    flex-direction: row;
    padding: 0;
}

.card-wide .card-image {
    width: 50%;
    border-radius: 0;
}

.card-image img {
    width: 80%;
    height: 80%;
}

.card-wide>ul {
    padding: 40px;
}

.card-wide>ul>li {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-gap: 24px;
    margin-bottom: 16px;
    color: black;
}


.card-wide .subtitle {
    font-size: 14px;
    line-height: 1.4;
    font-weight: 500;
    /*    margin-top: 8px;*/
    margin-bottom: 24px;
}

.card-wide>ul>li span {
    font-size: 16px;
}

.card-large>ul>li:last-of-type {
    margin-bottom: 2%;
    text-wrap: pretty;
}


.card-large.card-dark a,
.card-large.card-dark>ul {
    color: black;
}


.card-large button {
    margin-top: auto;
}

#locate>div {
    background: var(--brand-02);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 0;
    border-radius: 12px;
    box-shadow: var(--card-shadow);
    transition: var(--hover-timing);
    cursor: pointer;
}

#locate>div:hover {
    box-shadow: var(--card-hover);
}

#locate h2 {
    margin-top: 0;
}

#locate p {
    line-height: 1.5;
    margin-bottom: 40px;
    width: 50%;
    text-align: center;
}


.btn-group {
    display: flex;
    flex-direction: row;
    grid-gap: 16px;
    text-decoration: none;
}

.btn-group a {
    text-decoration: none;
    color: inherit;
}

.custom-btn {
    /* width: 130px; */
    /* height: 40px; */
    color: #fff;
    border-radius: 40px;
    padding: 10px 25px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    margin-right: 20px;
    background: transparent;
    cursor: pointer;
    /* transition: all 0.3s ease; */
    position: relative;
    display: inline-block;
     box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
     7px 7px 20px 0px rgba(0,0,0,.1),
     4px 4px 5px 0px rgba(0,0,0,.1);
    outline: none;
  }

  .btn-8 {
    
    background-color: #f0ecfc;
  background-image: linear-gradient(315deg, #388de9 0%, #255ad7 74%);
  /* background-image: linear-gradient(315deg, #49c4f8 0%, #15d4b5 74%); */
    line-height: 42px;
    padding: 2px 20px;
    border: none;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .btn-8 span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }
  
  .btn-8:before{
     height: 0%;
     width: 2px;
  }
  .btn-8:after {
    width: 0%;
    height: 2px;
  }
  .btn-8:hover:before {
    height: 100%;
  }
  .btn-8:hover:after {
    width: 100%;
  }
  .btn-8:hover{
    background: transparent;
    color: #1c6ad1;

  }
  /* .btn-8 span:hover{
    color: #1c6ad1;
  } */
 
  /* .btn-8 span:before {
    width: 2px;
    height: 0%;
  } */
  /* .btn-8 span:after {
    height: 2px;
    
  } */
  /* .btn-8 span:hover:before {
    height: 100%;
  }
  .btn-8 span:hover:after {
    width: 100%;
  } */


.btn_animator_container{
    display: flex;
    height: auto;
    margin-bottom: 3%;
    flex-direction: row;
    overflow-x: scroll;
    align-items: center;
    margin-left: 7%;
    white-space: nowrap;
        &::-webkit-scrollbar {
            display: none;
          }

}

.product-card-container {
    padding-top: 1rem;
    display: flex;
    gap: 2.5rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    transition: all 1.5s ease;
  }

  .product-card {
    width: 18rem;
    border-radius: 8px;
    box-shadow: 0 7px 25px -10px rgba(0,0,0,0.33);
    overflow: hidden;
    position: relative;
  }
  
  .product-card, .product-card * {
    transition: all 0.5s ease;
    text-decoration: none;
  }


  .product-card:hover {
    box-shadow: 0 10px 25px -5px rgba(0,0,0,0.25);  
    transform: translateY(-3px);
  }
  
  .product-card .product-card-img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: 10% 10% 10% 10%;
  }
  
  .product-card:hover .product-card-img {
    height: 240px;
  }
  
  .product-card:hover .product-card-details {
    padding-bottom: 75px;
  }

  .product-card .product-card-details{
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    background: linear-gradient(rgba(0,0,0,0.025), transparent);
  }
  
  /*** Product Details: Produt Name & Product Price Styling [STARTS] ***/
  
  .product-card .product-card-details .product-name {
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    color: #555555;
  }
  
  .product-card .product-card-details .product-price {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #1b7ac6;
  }

  .product-card .product-wish-addtocart{
    position: absolute;
    bottom: -75px;
    right: 0;
    left: 0;
  }
  
  .product-card:hover .product-wish-addtocart{
    bottom: 1.1rem;
  }
  
  .product-card .product-wish-addtocart {
    display: flex;
    justify-content: space-between;
  }

  .product-card .product-wish-addtocart .addtocart-btn {
    border-radius: 32px 0 0 32px!important;
    width: 162px;
  }
  
  .product-card .product-wish-addtocart .wish-btn {
    margin-left: 1.2rem;
    width: 20px;
    height: 20px;
    padding: 1.3rem 1.3rem;
    background-color:#f5f5f5;
    /* color: #ffffff; */
    box-shadow: none;
    /* text-content: center; */
  }

  .wish-btn {
    display: flex;
    background-color: transparent;
    justify-content: center;
    align-items: center;
  }
  
  a.wish-btn{
    height: 21px;
    width: 21px;
    color: transparent;
    
  }
  a.wish-btn, a.addtocart-btn {
    
    background: #1b7ac6;
    padding-left: 12%;
    padding-top: 4%;
    /* padding: 0.6rem 2.2rem; */
    font-family: 'Open Sans',sans-serif;
    font-weight: 600!important;
    font-size: 15px;
    border-radius: 100px!important;
    color: #ffffff;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.133);
  }
  
  .addtocart-btn:hover, .wish-btn:hover {
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.25);
  }


@media (max-width: 840px) {

    .product-card-container {
      justify-content: center;
    }
    
    .product-card .product-card-img {
      height: 240px;
    }
    
    .product-card .product-card-details {
      padding-bottom: 72px;
    }
    
    .product-card .product-wish-addtocart{
      bottom: 1.1rem;
    }
    
    .carousel-inner img {
      object-position: center;
    }
    
  }

  @media (max-width: 431px) {



   

    .product-card-container {
        gap: 0.6rem;
      }
    .product-card {
        width: 45%;
        /* width: 40%; */
        height: 280px;
      }
      
      .product-card .product-card-img {
        height: 150px; /* Reduced height for smaller screens */
        
      }
      
      .product-card .product-wish-addtocart {
        bottom: 1.1rem; /* Adjusted position for smaller screens */
      }
      
      /* Adjust product name and price font size for smaller screens */
      .product-card .product-card-details .product-name {
        font-size: 14px; /* Adjusted font size for smaller screens */
      }
    
      .product-card .product-card-details .product-price {
        font-size: 14px; /* Adjusted font size for smaller screens */
      }

      .product-card .product-wish-addtocart {
        font-size: 10px;
        bottom: 1.1rem;
    }
    .product-card .product-wish-addtocart .addtocart-btn {
        border-radius: 32px 0 0 32px !important;
        width: 140px;
        height: 30px;
        font-size: 10px;
        text-align: center;
        margin-top: 10%;
        padding-top: 4%;
        justify-content: center;
    }

    .product-card .product-wish-addtocart{
        position: relative;
        bottom: 75px;
        right: 0;
        left: 0;
      }


     
    .product-card:hover .product-wish-addtocart{
        bottom: none;
        transform: none;
      }
    
    
  }



@media only screen and (max-width: 600px) {
    :root {
        --pg-margin: 16px;
    }

    section.hero {
        aspect-ratio: 1/1;
        padding-top: 64px;

    }

    section.hero h1 {
        --hero-text: 40px;
        width: 80%;
    }

    section.hero {
        background-size: 85%;
        background-position: 360% 60%;
    }




    .btn-group {
        /* display: flex; */
        flex-direction: column;
        margin-bottom: 10%;
    }







    .shop-pets,
    .services {
        display: flex;
        flex-direction: column;
        grid-gap: 28px;
        
        list-style: none;
        /* Remove default bullets */
        padding-left: 0;

    }

    .services>li {
        display: flex;
        flex-direction: column;
    }

    .services>li>.card-image {
        width: 100%;
    }

    section:not(.hero) {
        padding: var(--pg-margin);
    }

    #locate p {

        width: 80%;
    }

    


}

@media only screen and (min-width: 600px) {
    :root {
        --pg-margin: 24px;
    }

    section.hero h1 {
        --hero-text: 40px;
        width: 60%;
    }

    section.hero {
        aspect-ratio: 3/2;
        background-size: 50%;
        background-position: 90% 70%;
        padding-top: 64px;

    }

    nav {
        position: relative;

    }

    nav ul.navigation-menu {
        position: absolute;
        top: 64px;
        background: var(--light-01);
        left: 0;
        z-index: -1;
        width: 100vw;
    }

    nav ul.navigation-menu li a {
        font-size: 16px;
        white-space: nowrap;
    }

    nav ul.navigation-menu .subnav {
        display: none;
    }

    nav ul.navigation-menu>li:hover .subnav {
        display: none;
    }

    .btn-group {
        display: flex;
        flex-direction: column;
    }


    .shop-pets {
        display: grid;
        grid-template-columns: 0fr 1fr;
        grid-row: auto auto;
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        
    }
    

    .services {
        display: flex;
        flex-direction: column;
        grid-gap: 24px;
    }

    section:not(.hero) {
        padding: var(--pg-margin);

    }

    #locate p {

        width: 60%;
    }

}

@media only screen and (min-width: 1200px) {
    :root {
        --pg-margin: 48px;
    }

    section.hero h1 {
        --hero-text: 48px;
    }

    section.hero {
        aspect-ratio: 2/1;

    }

    .btn-group {
        flex-direction: row;
    }

    .shop-pets,
    .services {
        /* width: 85rem; */
        display: flex;
        flex-direction: row;
        grid-gap: 24px;
        flex-wrap: wrap;
        overflow-x: scroll;
        overflow-y: hidden;
        gap: 5rem;
        white-space: nowrap;
        &::-webkit-scrollbar {
            display: none;
          }
    }

    ul.subnav {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row: auto auto;
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        padding: 48px;
    }


    nav ul.navigation-menu {
        top: 0;
        z-index: 1;
        background: none;
        width: auto;
        position: relative;
    }

    nav ul.navigation-menu .subnav {
        display: flex;
    }

    nav ul.navigation-menu>li:hover .subnav {
        display: flex;
    }

    #locate p {

        width: 40%;
    }

   
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    :root {
        --pg-margin: 80px;
    }

    section.hero h1 {
        --hero-text: 56px;
    }

    nav .navigation-menu>li>ul.subnav {
        padding: 48px;
        grid-gap: 24px;
    }

    section.hero {
        aspect-ratio: 3/1;
        background-size: 30%;
        background-position: 90% 60%;
    }

    .shop-pets,
    .services {
        display: flex;
        flex-direction: row;
        grid-gap: 6rem;
    }

    nav ul.navigation-menu {
        top: 0;
        z-index: 1;
        background: none;
        width: auto;
        position: relative;
    }

    ul.subnav {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row: auto auto;
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        padding: 48px;
    }

    nav ul.navigation-menu>li:hover .subnav {
        display: grid;
    }

    #locate p {

        width: 40%;
    }



}

@media only screen and (max-width: 431px) {

    

    /* .product-card {
        width: 179px;
        height: 184px;
        border-radius: 8px;
        box-shadow: 0 7px 25px -10px rgba(0, 0, 0, 0.33);
        overflow: hidden;
        position: relative;
    }

    .product-card .product-card-img {
        height: 150px;
    }
    .product-card:hover .product-card-img {
        height: 132px;
    }

    a.wish-btn, a.addtocart-btn {
        background: #2ecc71;
        padding: 10px 6px 5px 32px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600 !important;
        font-size: 12px;
        border-radius: 100px !important;
        color: #ffffff;
        box-shadow: 0 2px 15px rgba(0, 0, 0, 0.133);
    } */

    .shop-pets {
        width: 100%;
        display: block;
        overflow-x: scroll;
        overflow-y: hidden;
        gap: 5rem;
        white-space: nowrap;
        &::-webkit-scrollbar {
            display: none;
          }
    }

    .card-large,
    .card-med {
        height: 15rem;
        width: 12rem;
        overscroll-behavior-x: center;
        margin-right: 1rem;
        display: inline-block;

    }

    .card-large>ul>li:last-of-type {
        margin-top: 10%;
        margin-bottom: 0px;
        font-size: 15px;
    }

    .card-large button {
        font-size: 12px;
        margin-top: auto;
        height: 14px;
    }

}
