.Explore-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.Explore-container-heading {
  margin-bottom: 1rem;
  font-size: 20px;
}

.Explore-services-grid a {
  text-decoration: none;
  color: inherit;
}

@media (max-width: 769px) {
  .Explore-container-heading {
    margin-top: 3rem;
  }
}

.Explore-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.Explore-tab {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  background-color: #e0e0e0;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 5px;
  flex: 1; /* Ensure tabs take equal space */
  text-align: center;
}

.Explore-tab:hover {
  background-color: #d0d0d0;
}

.Explore-tab.Explore-active {
  background-color: #2d6cb0;
  color: white;
}

.Explore-content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px 0;
}

.Explore-services-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.Explore-service-card {
  background-color: white;
  background-size: cover;
  background-position: center;
  border: none;
  padding: 20px;
  width: 300px;
  height: 200px;
  text-align: center;
  background: rgba(32, 186, 18, 0.5);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white; /* Text color for readability over the background image */
}

.Explore-service-grid .Explore-service-card a {
  text-decoration: none;
}

.Explore-service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.Explore-service-card button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.Explore-service-card button:hover {
  background-color: rgba(20, 122, 205, 0.913);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .Explore-container {
    padding: 10px;
  }

  .Explore-tabs {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .Explore-tab {
    margin: 5px;
    padding: 8px 10px;
    font-size: 14px;
  }

  .Explore-services-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two cards per row */
    gap: 10px; /* Adjust gap as needed */
    justify-content: center;
  }

  .Explore-service-card {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 432px) {
  .Explore-services-grid {
    grid-template-columns: 1fr 1fr; /* Two cards per row for very small screens */
  }
}
