:root {
  --color-white-100: hsl(206, 5%, 100%);
  --color-white-200: hsl(206, 5%, 96%);
  --color-white-300: hsl(206, 5%, 80%);
  --color-white-400: hsl(206, 5%, 65%);
  --color-white-500: hsl(206, 5%, 50%);
  --color-black-100: hsl(213, 20%, 9%);
  --color-black-200: hsl(213, 23%, 8%);
  --color-black-300: hsl(214, 21%, 6%);
  --color-black-400: hsl(210, 21%, 6%);
  --color-black-500: hsl(216, 22%, 4%);
  --color-red-100: hsl(349, 100%, 90%);
  --color-red-200: hsl(349, 100%, 80%);
  --color-red-300: hsl(349, 100%, 70%);
  --color-red-400: hsl(349, 100%, 60%);
  --color-red-500: hsl(349, 100%, 45%);
  --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

/* Add these styles to your existing CSS */
.Headofmenu-item.dropdown:hover .premdropdown-content {
  display: block;
}


.Headofsearch {
    position: relative;
    width: 300px; /* Adjust as needed */
}

.Headofsearch a{
  text-decoration: none;/* Adjust as needed */
  color: inherit;
}

.Headofsearch-form {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
}

.Headofsearch-input {
    flex: 1;
    border: none;
    padding: 10px;
    outline: none;
    font-size: 14px;
}

.Headofsearch-submit {
    /* background: #007bff; */
    border: none;
    padding: 0 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}




.search-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 5px;
  padding: 0;
  overflow: scroll;
}

.search-result-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s;
}

.search-result-item:last-child {
  border-bottom: none;
}

.search-result-item:hover {
  background-color: #f0f0f0;
}

.search-result-item img {
  object-fit: cover;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 15px;
}

.search-result-item a {
  text-decoration: none;
  color: #333;
  flex: 1;
  font-size: 14px;
}

.close-search {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-search svg {
  width: 20px;
  height: 20px;
  fill: currentColor;
}

/* Hide scrollbar */
.search-dropdown::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.search-dropdown {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Hide scrollbar */




.premdropdown-content {
  display: none;
  width: 40rem;
  height: 30rem;
  position: fixed; /* Change to absolute for better positioning */
  z-index: 9;
  padding: 2rem;
  overflow-y: scroll;
  background-color: var(--color-white-100);
  box-shadow: var(--shadow-medium);
  white-space: nowrap;
    /* width: 100%; */
    &::-webkit-scrollbar {
        display: none;
    }
}

/* Update the categories name items */
.categories_nameitems {
  display: flex;
  /* grid-template-columns: repeat(3, minmax(0, 1fr)); Change column size as needed */
  gap: 1rem; /* Adjust the gap between items */
}

/* Update the prem dropdown list */
.premdropdown-list{
  display:contents; /* Ensures list items take the grid properties of the parent */
}

/* Ensure list items look good */
.cat-list-item {
  padding: 0.5rem;
  cursor: pointer;
  /* text-align: center; */
}

.premdropdown-list a {
  text-decoration: none;
  color: #000000;
}


@media only screen and (max-width: 768px) {
  .premdropdown-content {
    width: 100%;
    position: static;
    padding: 1rem;
    background-color: var(--color-white-100);
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    max-height: 50vh; /* Make it scrollable */
    overflow-y: auto;
  }

  .premdropdown-list {
    grid-template-columns: 1fr;
  }

  .Headofmenu-item.dropdown .dropdown-content {
    position: static;
  }
}

/* For smaller screens, such as mobile devices */
@media only screen and (max-width: 576px) {
  .premdropdown-content {
    max-height: 50vh; /* Adjust the height as needed */
    overflow-y: auto; /* Make it scrollable */
    display: block; /* Show the dropdown by default on mobile */
  }

  .premdropdown-list {
    gap: 0.5rem;
  }

  .Headofmenu-item.dropdown .dropdown-content {
    position: static;
  }
}

/* Ensuring dropdown is shown by default on mobile */
@media only screen and (max-width: 576px) {
  .Headofmenu-item.dropdown .premdropdown-content {
    display: block;
    border: 2px solid #ededed;
    border-radius: 10px;
  }
}


/* Ensure dropdown content is aligned correctly */
.Headofmenu-item.dropdown .dropdown-content {
  left: 0;
  top: 100%;
}

/* Remove underline from links */
.Headofmenu-link,
.Headofbrand {
  text-decoration: none;
}

.Headofmenu-link:hover,
.Headofbrand:hover {
  text-decoration: none;
  color: #1a77c4;
}


.close-icon {
  font-size: 1.5rem;
  cursor: pointer;
}

.sidebar-content {
  padding: 1rem;
}

.sidebar-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-content ul li {
  padding: 0.75rem 0;
  border-bottom: 1px solid #e0e0e0;
  text-decoration: none;
}

.sidebar-content ul li a {
  color: inherit;
  text-decoration: none;
}


.sidebar-content ul li:last-child {
  border-bottom: none;
}


.cat-list-item {
  padding: 0.5rem;
  /* border-bottom: 1px solid #e0e0e0; */
}

.cat-list-item:last-child {
  border-bottom: none;
}

/* Remove underline from links */
.Headofmenu-link,
.Headofbrand {
  text-decoration: none;
}

/* Optionally, you can also add a hover effect */
.Headofmenu-link:hover,
.Headofbrand:hover {
  text-decoration: none;
  /* Ensure no underline on hover */
  color: #1a77c4;
  /* Change color on hover if desired */
}


.custom-cart-icon {
  color: #000000;
  /* Black color for cart icon */
}

.custom-cart-icon:hover {
  color: #333333;
  /* Optional: Darker shade on hover */
}


/* Hide the categories option for screens 431px and smaller */
/* @media only screen and (max-width: 431px) {
    .Headofmenu-item:nth-child(3) {
        display: none;
    }
} */



/* Styles for the dropdown menu */






.toper-right {
  gap: 1rem;
  justify-content: end;
  align-items: center;
  display: flex;
}




.Headofcart {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  font-size: 1.35rem;
  color: var(--color-black-100);
  cursor: pointer;
}




.Headofsection {
  margin: 0 auto;
  padding: 5rem 0 2rem;
}

.Headofcontainer {
  max-width: 80rem;
  height: auto;
  margin: 0 auto;
  padding: 0 1.25rem;
}

.Headofcentered {
  text-align: center;
  vertical-align: middle;
  margin-bottom: 1rem;
}

.Headofbtn {
  display: inline-block;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  user-select: none;
  outline: none;
  border: none;
  border-radius: 0.25rem;
  text-transform: unset;
  transition: all 0.3s ease-in-out;
}

.Headofbtn-darken {
  padding: 0.75rem 2rem;
  color: var(--color-white-100);
  background-color: var(--color-black-200);
  box-shadow: var(--shadow-medium);
}

.Headofbtn-neutral {
  padding: 0.75rem 2rem;
  color: var(--color-black-500);
  background-color: var(--color-white-100);
  box-shadow: var(--shadow-medium);
}

.partsecond {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

/* .partsecond-cart-text {
  text-decoration: none;
  color: black;
} */

.Headofheader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: auto;
  margin: 0 auto;
  background: var(--color-white-100);
  box-shadow: var(--shadow-medium);
}

.Headofnavbar {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: center;
  align-content: center;
  height: auto;
  padding-top: 0.5rem;
}

@media only screen and (min-width: 62rem) {
  .Headofnavbar {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    width: 100%;
    height: 4.25rem;
    padding-top: 0;
    margin: 0 auto;
  }
}

.Headofbrand {
  justify-self: start;
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: -1px;
  text-transform: uppercase;
  color: #1a77c4;
  ;
}

.Headofsearch {
  grid-column: span 2;
  order: 3;
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding-block: 1rem;
}

.Headofsearch-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
  height: auto;
  padding-inline: 1.5rem;
  border-radius: 3rem;
  background: var(--color-white-200);
}

.Headofsearch-input {
  border: none;
  display: block;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: inherit;
  width: 100%;
  height: 100%;
  padding: 0.75rem 0;
  background: none;
  color: var(--color-black-500);
}

.Headofsearch-input:focus {
  outline: none;
}

.Headofsearch-submit {

  border: none;
  display: inline-block;
  justify-self: end;
  width: auto;
  height: auto;
  margin-left: 1rem;
  font-size: 1.35rem;
  font-weight: normal;
  line-height: 1;
  color: var(--color-white-500);
  background: transparent;
}

.Headofsearch-submit:focus {
  outline: none;
}

.Headoflogin-btn {
  /* Add styling for the login button */
  display: inline-block;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  user-select: none;
  outline: none;
  border: none;
  border-radius: 0.25rem;
  padding: 0.75rem 2rem;
  background-color: rgb(28, 100, 184);
  color: var(--color-white-100);
  transition: all 0.3s ease-in-out;
}



.Headoflogin-btn:hover {
  background-color: var(--color-red-400);
}

.logo-head {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo-head img {
  height: 3rem;
}

@media only screen and (min-width: 62rem) {
  .Headofsearch {
    gap: 3rem;
    align-items: center;
    display: flex;
    justify-self: center;
    grid-column: initial;
    order: initial;
    width: 100%;
    height: auto;
  }

  .Headofsearch-form {
    max-width: 28rem;
  }
}

.Headofmenu {
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: auto;
  overflow: hidden;
  padding-block: 3rem 2rem;
  background: var(--color-white-100);
  box-shadow: var(--shadow-medium);
  transition: all 0.45s ease-in-out;
}

.Headofmenu.is-active {
  z-index: 9;
  top: 0;
  width: 100%;
  height: inherit;
}

.Headofmenu-inner {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  /* align-items: center; */
  /* justify-content: center; */
  row-gap: 1.25rem;
}

.Headofmenu-link {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: inherit;
  text-transform: uppercase;
  color: var(--color-black-500);
  transition: all 0.35s ease-in-out;
}

.Headofmenu-link:hover {
  outline: none;
  color: #1a77c4;
}

@media only screen and (min-width: 36rem) {
  .Headofmenu {
    position: relative;
    justify-self: end;
    top: 0;
    left: inherit;
    width: auto;
    height: auto;
    padding-block: 0;
    background: none;
    box-shadow: none;
    transition: none;
  }

  .Headofmenu-inner {
    display: flex;
    flex-direction: row;
    column-gap: 1.25rem;
    margin: 0 auto;
  }

  .Headofmenu-link {
    text-transform: capitalize;
  }
}

.Headofburger {
  position: relative;
  justify-self: end;
  cursor: pointer;
  user-select: none;
  z-index: 10;
  width: 1.6rem;
  height: 1.15rem;
  border: none;
  outline: none;
  background: none;
  visibility: visible;
  transform: rotate(0deg);
  transition: 0.35s ease;
}

@media only screen and (min-width: 36rem) {
  .toper-right {
    display: none;
    visibility: hidden;
  }


}

.custom-cart-icon {
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-cart-icon svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}

.Headofburger-line {
  position: absolute;
  display: block;
  right: 0;
  width: 100%;
  height: 2.1px;
  border: none;
  outline: none;
  opacity: 1;
  border-radius: 1rem;
  transform: rotate(0deg);
  background-color: var(--color-black-100);
  transition: 0.25s ease-in-out;
}

.Headofburger-line:nth-child(1) {
  top: 0px;
}

.Headofburger-line:nth-child(2) {
  top: 0.5rem;
  width: 70%;
}

.Headofburger-line:nth-child(3) {
  top: 1rem;
}

.Headofburger.is-active .Headofburger-line:nth-child(1) {
  top: 0.5rem;
  transform: rotate(135deg);
}

.Headofburger.is-active .Headofburger-line:nth-child(2) {
  opacity: 0;
  visibility: hidden;
}

.Headofburger.is-active .Headofburger-line:nth-child(3) {
  top: 0.5rem;
  transform: rotate(-135deg);
}

@media only screen and (max-width: 769px) {
  .Headofsearch {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 10px;
  }
}

@media only screen and (max-width: 576px) {
  .partsecond {
    display: none;
  }
}



@media only screen and (max-width: 426px) {
  .partsecond {
    display: none;
  }

  .Headofsearch {

    padding-block: 1rem;
  }
}