.FruityFooter-contact-area {
  /* background: linear-gradient(135deg, #1A1E25, #353C46); */
  background: linear-gradient(135deg, #000000, #0d2e5e);
  color: #ffffff;
  padding: 3rem 0;
  text-align: center;
  margin-top: 2rem;
  @media (max-width:431px) {
    padding-top: 30px;
  }
}

.FruityFooter-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.FruityFooter-row {
  display: flex;
  justify-content: center;
}

.FruityFooter-col-lg-6 {
  flex: 0 0 100%;
  max-width: 100%;
}

.FruityFooter-offset-lg-3 {
  margin-left: auto;
  margin-right: auto;
}

.FruityFooter-contact-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

.FruityFooter-contact-content p {
  font-size: 16px;
  margin: 1rem 0;
  color: #bbbbbb;
}

.FruityFooter-contact-content h6 {
  color: #cccccc;
  font-size: 15px;
  font-weight: 400;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.FruityFooter-contact-content span {
  color: #ffffff;
  margin: 0 10px;
}

.FruityFooter-contact-social {
  margin-top: 30px;
}

.FruityFooter-contact-social > ul {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 0;
  list-style: none;
}

.FruityFooter-contact-social ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
  color: #ffffff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.FruityFooter-contact-social ul li a:hover {
  background: #0d5ca6;
  color: #1A1E25;
  border-color: #1189d4;
}

.FruityFooter-contact-content img {
  max-width: 210px;
  margin-bottom: 1rem;
}

.FruityFooter-footer {
  background-color: #1A1E25;
  padding: 2rem 0;
  color: #868c96;
}

.FruityFooter-footer p {
  margin: 0;
}

/* Responsive Design */
@media (max-width: 992px) {
  .FruityFooter-col-lg-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .FruityFooter-col-lg-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .FruityFooter-contact-content p {
    font-size: 14px;
  }

  .FruityFooter-contact-social ul {
    gap: 10px;
  }

  .FruityFooter-contact-social ul li a {
    height: 35px;
    width: 35px;
  }
}

@media (max-width: 576px) {
  .FruityFooter-contact-content h6 {
    font-size: 14px;
  }

  .FruityFooter-contact-social {
    margin-top: 20px;
  }

  .FruityFooter-contact-social ul li a {
    height: 30px;
    width: 30px;
  }

  .FruityFooter-container {
    
    padding: 1rem 2rem;
}
}

@media (max-width: 375px) {
  .FruityFooter-contact-content p {
    font-size: 12px;
  }

  .FruityFooter-contact-content h6 {
    font-size: 12px;
  }
}
