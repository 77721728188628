.Tradning_product_page {
  margin-top: 40px;
  margin-bottom: 10%;
}
.Tanding_product_see_more {
  margin-top: 0.5%;
  margin-left: 69%;
}

#content::-webkit-scrollbar {
  display: none;
}
.heading_name_tranding_product{
  font-family:'Times New Roman', Times, serif;
}
.product-link,
.product-link:focus,
.product-link:focus-visible {
  color: inherit; /* Inherit text color */
  text-decoration: none; /* Remove underline */
  outline: none; /* Remove focus outline */
}
/* Hiding scrollbar for IE, Edge and Firefox */

.headsection {
  display: flex;
}

.Trending_product_container{
    /* margin-bottom: 1rem; */
    padding-left: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    gap: 2rem;
    
    align-items: center;
    /* flex-wrap: wrap; */
    transition: all 1.5s ease;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
}




.trending-product-card {
  width: 18rem;
  border-radius: 8px;
  box-shadow: 0 7px 25px -10px rgba(0,0,0,0.33);
  overflow: hidden;
  position: relative;
}

.trending-product-card, .trending-product-card * {
  transition: all 0.5s ease;
  text-decoration: none;
}


.trending-product-card:hover {
  box-shadow: 0 10px 25px -5px rgba(0,0,0,0.25);  
  transform: translateY(-3px);
}

.trending-product-card .trending-product-card-img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: 10% 10% 10% 10%;
}

.trending-product-card:hover .trending-product-card-img{
  height: 240px;
}

.trending-product-card:hover .trending-product-card-details {
  padding-bottom: 75px;
}

.trending-product-card .trending-product-card-details{
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(rgba(0,0,0,0.025), transparent);
}

/*** Product Details: Produt Name & Product Price Styling [STARTS] ***/

.trending-product-card .trending-product-card-details .trending-product-name {
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  color: #555555;
}

.trending-product-card .trending-product-card-details .trending-product-price {
  font-family: 'Montserrat', sans-serif;
  font-weight:  500;
  font-size: 18px;
  color: #2e85cc;
}

.trending-product-card .product-wish-addtocart{
  position: absolute;
  bottom: -75px;
  right: 0;
  left: 0;
}

.trending-product-card:hover .product-wish-addtocart{
  bottom: 1.1rem;
}

.trending-product-card .product-wish-addtocart {
  display: flex;
  justify-content: space-between;
}

.trending-product-card .product-wish-addtocart .addtocart-btn {
  border-radius: 32px 0 0 32px!important;
  width: 162px;
}

.trending-product-card .product-wish-addtocart .wish-btn {
  margin-left: 1.2rem;
  width: 20px;
  height: 20px;
  padding: 1.3rem 1.3rem;
  background-color:#f5f5f5;
  /* color: #ffffff; */
  box-shadow: none;
  /* text-content: center; */
}

.add-wish-btn {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

a.wish-btn{
  height: 21px;
  width: 21px;
}
a.wish-btn, a.addtocart-btn {
  
  background: #2269c6;
  padding-left: 12%;
  padding-top: 4%;
  /* padding: 0.6rem 2.2rem; */
  font-family: 'Open Sans',sans-serif;
  font-weight: 600!important;
  font-size: 15px;
  border-radius: 100px!important;
  color: #ffffff;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.133);
}

.addtocart-btn:hover, .wish-btn:hover {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.25);
}



@media (max-width: 840px) {

  
  
  .trending-product-card .trending-product-card-img {
    height: 240px;
  }
  
  .trending-product-card .trending-product-card-details {
    padding-bottom: 72px;
  }
  
  .trending-product-card .product-wish-addtocart{
    bottom: 1.1rem;
  }
  
  
  
}





@media (max-width: 431px) {



   

  .Trending_product_container{
    gap: 10px;
  }
  .trending-product-card{
      width: 95%;
      /* width: 40%; */
      height: 280px;
      margin-bottom: 8%;
    }
    
    .trending-product-card .trending-product-card-img{
      height: 150px; /* Reduced height for smaller screens */
      width: 180px;
    }
    
    .trending-product-card .product-wish-addtocart {
      bottom: 1.1rem; /* Adjusted position for smaller screens */
    }
    
    /* Adjust product name and price font size for smaller screens */
    .trending-product-card .trending-product-card-details .trending-product-name {
      font-size: 14px; /* Adjusted font size for smaller screens */
    }
  
    .trending-product-card .trending-product-card-details .trending-product-price {
      font-size: 14px; /* Adjusted font size for smaller screens */
    }

    .trending-product-card .product-wish-addtocart {
      font-size: 10px;
      bottom: 1.1rem;
  }
  .trending-product-card .product-wish-addtocart .addtocart-btn {
      border-radius: 32px 0 0 32px !important;
      width: 140px;
      height: 30px;
      font-size: 10px;
      text-align: center;
      margin-top: 10%;
      padding-top: 4%;
      justify-content: center;
  }

  .trending-product-card .product-wish-addtocart{
      position: relative;
      bottom: 75px;
      right: 0;
      left: 0;
    }


   
  .trending-product-card:hover .product-wish-addtocart{
      bottom: none;
      transform: none;
    }
  
  
}








@media (max-width: 769px) {
  .Tradning_product_page {
    margin-top: 12%;
  }
}

@media (max-width: 431px) {
  .Tradning_product_page {
    margin-top: 12%;
  }
}

@media (max-width: 431px) {
  .Tradning_product_page {
    margin-top: 0%;
  }
  .Tanding_product_see_more {
    margin-left: 64%;
  }
  .heading_name_tranding_product {
    margin-top: 20px;
    font-size: 25px;
  }
  .tranding_page_Card {
    display: inline-block;
    width: 9rem;
    height: 14rem;
  }
  .trending_product_img {
    border-radius: 0px;
    height: 8rem;
    width: 9rem;
    margin-bottom: 1rem;
  }
  .tranding_product_text_box_2 {
    margin-left: -6%;
  }
  .tranding_page_product_name {
    font-size: 16px;
  }
  .tranding_page_product_price {
    margin-top: 5%;
    margin-bottom: 10%;
    font-size: 14px;
    color: black;
  }
  .tranding_page_product_price {
    margin-left: 66%;
    font-size: 14px;
  }
  .Tanding_product_see_more {
    margin-left: 60%;
    font-size: 14px;
  }
}

@media (max-width: 376px) {
  .heading_name_tranding_product {
    font-size: 16px;
  }
  .tranding_page_Card {
    display: inline-block;
    width: 9rem;
    height: 14rem;
  }
  .trending_product_img {
    border-radius: 0px;
    height: 8rem;
    width: 9rem;
    margin-bottom: 1rem;
  }
  .tranding_product_text_box_2 {
    margin-left: -6%;
  }
  .tranding_page_product_name {
    font-size: 16px;
  }
  .tranding_page_product_price {
    margin-top: 5%;
    margin-bottom: 10%;
    font-size: 14px;
    color: black;
  }
  .tranding_page_product_price {
    margin-left: 66%;
    font-size: 14px;
  }
  .Tanding_product_see_more {
    margin-left: 60%;
    font-size: 14px;
  }
}
