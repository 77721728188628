.heading_section_choose_us{
    align-items: center;
    display: flex;
    flex-direction: column;
    line-height: 63px;
}
.heading_section_choose_us{
    font-size: 20px
}
.choose_us_inner_container{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.choose_us_object img{
    height: 15rem;
    width: 15rem;
    align-items: center;
}

@media (max-width: 769px){
    .choose_us_inner_container{
        flex-wrap: wrap;
        text-align: center;
    }
    
    .choose_us_object img{
        height: 10rem;
        width: 10rem;
        align-items: center;
    }
}
@media (max-width: 431px) {
    .choose_us_inner_container{
        flex-wrap: wrap;
        text-align: center;
    }
    .choose_us_heading{
        font-size: 16px;
    }
    .heading_section_choose_us p{
        font-size: 12px;
    }
    .choose_us_object h4{
        font-size: 12px;
    }
    .choose_us_object p{
        font-size: 12px;
    }
    .choose_us_object{
        margin: 1px;
    }

    .choose_us_object img{
        height: 8rem;
        width: 8rem;
        align-items: center;
    }
}