.ServiceBookingPage-container {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  padding: 16px;
  background-image: url('https://images.unsplash.com/photo-1669835122130-634df4039083?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-size: cover;
  background-position: center;
}

.circle-number {
  background-color:#12589e; /* You can change this color as needed */
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  
  flex-shrink: 0; /* Ensures the circle doesn't shrink */
}



.why-choose{
  background-color: #f1f1f1;
  padding: 2rem;
  text-align: center;
}

.why-choose-heading{
  color: black;
  font-weight: 700;
}

.why-choose-content{
  display: flex;
  flex-wrap: wrap;
  gap: 6rem;
  justify-content: center;
  margin-top: 2rem;
  @media (max-width:431px) {
    gap: 2rem;
    justify-content: start;
  }

  @media (max-width:416px) {
    gap: 10px;
  }
}

.choose-reason{
  width: 10rem;
  height: auto;
  align-items: center;
  
  display: flex;
  flex-direction: column;
  @media (max-width:431px) {
    /* justify-content: start; */
    width: 9rem;
  }
}

.choose-point{
  background-color: rgb(13, 99, 180);
  width: 3rem;
  height: 3rem;
  padding-left: 1.3rem;
  padding-top: 0.8rem;
  border-radius: 50%;
  @media (max-width:431px) {
    width: 2rem;
  height: 2rem;
  padding-left: 0.8rem;
    padding-top: 0.3rem;
  }
}

.reason1{
  color: white;
  align-self: center;
}
.reason-details{
  color: #718096;
  font-size: 14px;
  @media (max-width:431px) {
    font-size: 12px;
  }
}

.ServiceBookingPage-sidebar-description{
  font-size: medium;
  color: #718096;
}

.BookingPage-mt-4 {
  margin-top: 1rem; /* Adjust as needed */
}

.BookingPage-block {
  display: block;
}

.BookingPage-text-zinc-700 {
  color: #718096; /* Adjust color as needed */
}

.BookingPage-dark .BookingPage-text-zinc-300 {
  color: #a0aec0; /* Adjust color as needed */
}

.BookingPage-w-full {
  width: 100%;
}

.BookingPage-p-2 {
  padding: 0.5rem; /* Adjust padding as needed */
}

.BookingPage-border {
  border-width: 1px;
}

.BookingPage-border-zinc-300 {
  border-color: #cbd5e0; /* Adjust color as needed */
}

.BookingPage-rounded {
  border-radius: 0.25rem; /* Adjust radius as needed */
}

.BookingPage-mt-2 {
  margin-top: 0.5rem; /* Adjust as needed */
}

.BookingPage-bg-orange-500 {
  background-color: #1a5aa4; /* Adjust color as needed */
}

.BookingPage-text-white {
  color: #fff; /* Adjust color as needed */
}


.BookingPage-Details-header{
  display: flex;
  gap: 0.5rem;
}

@media (min-width: 1024px) {
  .ServiceBookingPage-container {
    flex-direction: row;
  }
}

.ServiceBookingPage-content {
  width: 100%;
  padding: 16px;
}

@media (min-width: 1024px) {
  .ServiceBookingPage-content {
    width: 66.6667%;
  }
}

.ServiceBookingPage-header {
  color: rgb(255, 255, 255);
}

.ServiceBookingPage-title,
.ServiceBookingPage-subtitle {
  font-size: 2.5rem;
  font-weight: bold;
}

.ServiceBookingPage-description {
  margin-top: 8px;
  font-size: 1.125rem;
}

.ServiceBookingPage-features {
  display: flex;
  margin-top: 32px;
  gap: 16px;
}

.ServiceBookingPage-feature-card {
  width: 96px;
  height: 96px;
  border-radius: 9999px;
  border: 4px solid #12589e;
  overflow: hidden;
}

.ServiceBookingPage-feature-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ServiceBookingPage-feature-text {
  margin-top: 8px;
  text-align: center;
  font-weight: 600;
}

.ServiceBookingPage-sidebar {
  width: 100%;
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  color: black;
}

@media (min-width: 1024px) {
  .ServiceBookingPage-sidebar {
    width: 30.3333%;
  }
}

.ServiceBookingPage-sidebar-title {
  color: #1a5aa4;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 16px;
}

.ServiceBookingPage-sidebar-description {
  margin-bottom: 16px;
}

.ServiceBookingPage-sidebar-list {
  list-style-type: disc;
  padding-left: 16px;
  margin-bottom: 16px;
}

.ServiceBookingPage-list-item {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 8px;
}

.ServiceBookingPage-button {
  width: 100%;
  padding: 8px 0;
  color: white;
  background-color: #1a5aa4;
  border-radius: 8px;
}


@media (max-width:431px) {
  .ServiceBookingPage-sidebar-description{
    font-size: 12px;
    color: #718096;
  }
}