@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');



.Thankyoupage-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  backdrop-filter: blur(10px);
  animation: fadeIn 1s ease-in-out;
}

.Thankyoupage-card {
  background: rgba(255, 255, 255, 0.9);
  padding: 2rem 3rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 500px;
  transform: translateY(20px);
  animation: slideUp 1s ease-out forwards;
}

.Thankyoupage-heading {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
  font-weight: 700;
  animation: fadeInText 1s ease-in-out 0.5s forwards;
  opacity: 0;
}

.Thankyoupage-message,
.Thankyoupage-redirect {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #555;
  font-weight: 400;
  animation: fadeInText 1s ease-in-out 0.8s forwards;
  opacity: 0;
}

.Thankyoupage-redirect {
  margin-top: 1rem;
  font-style: italic;
  color: #777;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInText {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
