.popular-brand-bg {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-bottom: 3rem;
    margin-top: 2rem;
}

.popular-brand-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.popular-brand-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #1f2937; /* Tailwind color zinc-900 */
    @media (max-width:432px) {
        font-size: 1.2rem;
    }
}

.popular-brand-view-all {
    color: #235dbc; /* Tailwind color blue-500 */
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
}

.popular-brand-carousel-container {
    position: relative;
}

.popular-brand-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.8);
    padding: 0.75rem;
    border-radius: 50%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;

    @media (max-width:432px) {
        display: none;
    }
}

.popular-brand-button-left {
    left: 0;
}

.popular-brand-button-right {
    right: 0;
}

.popular-brand-button:hover {
    background-color: #1b55b3;
    color: #ffffff;
}

.popular-brand-carousel {
    display: flex;
    overflow-x: auto;
    gap: 1.5rem;
    scroll-behavior: smooth;
    padding-bottom: 1rem;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

.popular-brand-carousel::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

.popular-brand-card {
    flex: none;
    width: 8rem;
    height: 8rem;
    background-color: #ffffff;
    border-radius: 0.75rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width:432px) {
        width: 6rem;
        height: 6rem;
    }
}

.popular-brand-image {
    width: 6rem;
    height: 6rem;
    object-fit: contain;
    @media (max-width:432px) {
        width: 4.5rem;
        height: 4.5rem;
    }
}

.popular-brand-button svg {
    width: 1.5rem;
    height: 1.5rem;

    @media (max-width:432px) {
        width: 1rem;
        height: 1rem;
    }
}
