.AD-dashboard {
  display: flex;
  width: 100%;
  flex-direction: column; /* Change to column for mobile view */
}

.AD-sidebar {
  width: 250px;
  background-color: #343a40;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex-shrink: 0;
}

.AD-sidebar nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.AD-sidebar nav ul li {
  margin-bottom: 20px;
}

.AD-sidebar nav ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s;
}

.AD-sidebar nav ul li a:hover {
  color: #007bff;
}

.AD-main-content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
}

.AD-admin-panel {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.AD-admin-panel h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.AD-admin-form {
  display: flex;
  flex-direction: column;
}

.AD-form-group {
  margin-bottom: 15px;
}

.AD-form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.AD-form-group input,
.AD-form-group textarea,
.AD-form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.AD-form-group input:focus,
.AD-form-group textarea:focus,
.AD-form-group select:focus {
  border-color: #007bff;
}

.AD-form-group textarea {
  resize: vertical;
  min-height: 100px;
}

.AD-submit-button {
  padding: 15px;
  background-color: #15ba2d;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.AD-submit-button:hover {
  background-color: #0056b3;
}


/* Existing CSS styles */
.AD-message {
  background-color: #28a745;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 15px;
}


.alert {
  padding: 20px;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; /* Green */
  /* color: white; */
  margin-bottom: 15px;
  border-radius: 5px;
  /* width: 100%; */
  max-width: 500px;
  box-sizing: border-box;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}


/* Responsive Styles */
@media (min-width: 768px) {
  .AD-dashboard {
    flex-direction: row; /* Change to row for larger screens */
  }
}

@media (max-width: 767px) {

  .alert {
    padding: 10px;
}


  .AD-sidebar {
    width: 100%; /* Full width sidebar for mobile */
    order: 1;
  }

  .AD-main-content {
    order: 2;
    padding: 10px; /* Reduce padding for mobile */
  }

  .AD-admin-panel {
    width: 100%;
    height: 100%; /* Full width form for mobile */
    box-shadow: none; /* Remove box-shadow for mobile */
    border-radius: 0; /* Remove border-radius for mobile */
    padding: 15px; /* Adjust padding for mobile */
  }

  .AD-admin-panel h2 {
    font-size: 24px; /* Adjust font-size for mobile */
  }

  .AD-submit-button {
    padding: 10px; /* Adjust button padding for mobile */
  }
}
