:root {
    --primary-color: #010712;
    --secondary-color: #818386;
    --bg-color: #FCFDFD;
    --button-color: #3B3636;
    --h1-color: #3F444C;
  }
  
  [data-theme="dark"] {
    --primary-color: #FCFDFD;
    --secondary-color: #818386;
    --bg-color: #010712;
    --button-color: #818386;
    --h1-color: #FCFDFD;
  }
  
  * {
    margin: 0;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
  }
  
  .contact-container {
    display: flex;
    width: 100vw;
    height: 100vh;
    background: var(--bg-color);
  }

  .success-msg {
    color: green;
  }
  
  .left-col {
    width: 45vw;
    height: 100%;
    background-image: url("https://images.unsplash.com/photo-1536895058696-a69b1c7ba34f?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .contact-logo {
    width: 10rem;
    padding: 1.5rem;
  }
  
  .right-col {
    background: var(--bg-color);
    width: 50vw;
    height: 100vh;
    padding: 5rem 3.5rem;
  }
  
  .contact-heading,
  .contact-label,
  .description {
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    letter-spacing: 0.1rem;
  }
  
  .contact-heading {
    color:var(--h1-color);
    text-transform: uppercase;
    font-size: 2.5rem;
    letter-spacing: 0.5rem;
    font-weight: 300;
  }
  
  .contact-description {
    color: var(--secondary-color);
    font-size: 0.9rem;
    letter-spacing: 0.01rem;
    width: 40vw;
    margin: 0.25rem 0;
  }
  
  .contact-label,
  .description {
    color: var(--secondary-color);
    text-transform: uppercase;
    font-size: 0.625rem;
  }
  
  .contact-form {
    width: 31.25rem;
    position: relative;
    margin-top: 2rem;
    padding: 1rem 0;
  }
  
  .contact-input,
  .contact-textarea,
  .contact-label {
    width: 40vw;
    display: block;
  }
  
  .contact-input::placeholder,
  .contact-textarea::placeholder {
    color: var(--primary-color);
  }
  
  .contact-input,
  .contact-textarea {
    color: var(--primary-color);
    font-weight: 500;
    background: var(--bg-color);
    border: none;
    border-bottom: 1px solid var(--secondary-color);
    padding: 0.5rem 0;
    margin-bottom: 1rem;
    outline: none;
  }
  
  .contact-textarea {
    resize: none;
  }
  
  .contact-submit {
    text-transform: uppercase;
    font-weight: 300;
    background: var(--button-color);
    color: var(--bg-color);
    width: 10rem;
    height: 2.25rem;
    border: none;
    border-radius: 2px;
    outline: none;
    cursor: pointer;
  }
  
  .contact-input:hover,
  .contact-textarea:hover,
  .contact-submit:hover {
    opacity: 0.5;
  }
  
  .contact-submit:active {
    opacity: 0.8;
  }
  
  /* Toggle Switch */
  
  .theme-switch-wrapper {
    display: flex;
    align-items: center;
    text-align: center;
    width: 160px;
    position: absolute;
    top: 0.5rem;
    right: 0;
  }
  
  .description {
      margin-left: 1.25rem;
    }
  
  .theme-switch {
    display: inline-block;
    height: 34px;
    position: relative;
    width: 60px;
  }
  
  .theme-switch input {
    display:none;
  }
  
  .theme-slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
  }
  
  .theme-slider:before {
    background-color: #fff;
    bottom: 0.25rem;
    content: "";
    width: 26px;
    height: 26px;
    left: 0.25rem;
    position: absolute;
    transition: .4s;
  }
  
  .theme-switch input:checked + .theme-slider {
    background-color: var(--button-color);
  }
  
  .theme-switch input:checked + .theme-slider:before {
    transform: translateX(26px);
  }
  
  .theme-slider.round {
    border-radius: 34px;
  }
  
  .theme-slider.round:before {
    border-radius: 50%;
  }
  
  .contact-error,
  .contact-success {
    width: 40vw;
    margin: 0.125rem 0;
    font-size: 0.75rem;
    text-transform: uppercase;
    font-family: 'Jost';
    color: var(--secondary-color);
  }
  
  #success-msg {
    transition-delay: 3s;
  }
  
  @media only screen and (max-width: 950px) {
    .contact-logo {
      width: 8rem;
    }
    .contact-heading {
      font-size: 1.75rem;
    }
    .contact-description {
      font-size: 0.7rem;
    }
    .contact-input,
    .contact-textarea,
    .contact-submit {
      font-size: 0.65rem;
    }
    .description {
      font-size: 0.3rem;
      margin-left: 0.4rem;
    }
    .contact-submit {
      width: 7rem;
    }
    .theme-switch-wrapper {
      width: 120px;
    }
    .theme-switch {
      height: 28px;
      width: 50px;
    }
    .theme-slider:before {
      background-color: #fff;
      bottom: 0.25rem;
      content: "";
      width: 20px;
      height: 20px;
      left: 0.25rem;
      position: absolute;
      transition: .4s;
    }
    .theme-switch input:checked + .theme-slider:before {
      transform: translateX(16px);
    }
    .theme-slider.round {
      border-radius: 15px;
    }
    .theme-slider.round:before {
      border-radius: 50%;
    }
  }
  

  @media only screen and (max-width: 426px) {
    .contact-logo {
      width: 8rem;
    }
    .contact-heading {
      font-size: 1.50rem;
    }
    .contact-description {
      font-size: 0.7rem;
    }
    .contact-input,
    .contact-textarea,
    .contact-submit {
      font-size: 0.65rem;
    }
    .description {
      font-size: 0.3rem;
      margin-left: 0.2rem;
    }
    .contact-submit {
      width: 7rem;
    }

    .right-col {
        
        padding: 1rem 1.5rem;
    }
    
   
  }
  