.img-container-p4 {
  height: 23rem;
  width: 33rem;
}
.ani-container {
  margin-top: -7rem;
  height: 26rem;
  width: 29rem;
  margin-left: 0%;
}

.requestdemo {
  width: 100%;
  border-radius: 120px 0px 0px 0px;
  padding-top: 90px;
  padding-right: 0px;
  padding-left: 82px;
  display: flex;
  background: linear-gradient(45deg, #b77fe3, #ffa248); /* Adjust the width as needed */
}

.request-title {
  font-weight: 600;
}

.RequestTitle{
  color: white;
}
.passage {
  font-size: 17px;
  margin-top: 30px;
  color: #fffcfc;
}

.whatsapp-button,
.call-button {
  border: 2PX solid black;
  margin-right: 20px;
  margin-top: 20%;
  height: 50px;
  width: 170px;
  border-radius: 20px;
  justify-content: start;
  transition: transform 0.3s, box-shadow 0.3s;
}

.whatsapp-button {
  color: rgb(0, 0, 0);
  background-color: #ffffff;
}

.call-button {
  color: white;
  background-color: black;
}

.whatsapp-button:hover {
  transform: scale(1.05);
  /* Increase size by 5% */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  /* Add shadow effect */
}

.call-button:hover {
  transform: scale(1.05);
  /* Increase size by 5% */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  /* Add shadow effect */
}

.icon {
  margin-right: 10px;
  justify-content: center;
}

@media screen and (max-width: 1400px) {
  .whatsapp-button,
  .call-button {
    margin-top: 5%;
  }
}

@media screen and (max-width: 1200px) {
  .whatsapp-button,
  .call-button {
    margin-top: 5%;
  }

  .requestdemo {
    border-radius: 40px 0px 0px 0px;
    /* Adjust the width as needed */
  }
}

@media screen and (max-width: 1025px) {
  .whatsapp-button,
  .call-button {
    margin-right: 7px;
    margin-top: 4%;
  }
}

@media screen and (max-width: 769px) {
  .ani-container {
    display: none;
  }

  .whatsapp-button,
  .call-button {
    margin-right: 7px;
    margin-top: 2%;
    height: 45px;
    width: 150px;
  }

  .passage {
    font-size: 17px;
    margin-top: 10px;
    color: #868686;
  }

  .request-title {
    font-size: medium;
    font-weight: 600;
  }

  .but {
    margin-bottom: 20px;
  }

  .whatsapp-button {
    width: 250px;
  }

  .call-button {
    width: 250px;
    margin-top: 20px;
  }

  .img-container-p4 {
    height: fit-content;
    width: fit-content;
  }
}

@media screen and (max-width: 768px) {
  .requestdemo {
    padding-right: 2rem;
    padding-left: 3rem;
    border-radius: 40px 0px 0px 0px;
  }

  .img-container-p4 {
    height: 20rem;
  }
}

@media screen and (max-width: 431px) {
  .requestdemo {
    padding-left: 50px;
    border-radius: 26px 0px 0px 0px;
    background: linear-gradient(45deg, #b77fe3, #ffa248);
  }

  .whatsapp-button,
  .call-button {
    margin-right: 7px;
    margin-top: 3%;
    height: 45px;
    width: 150px;
  }
.RequestTitle{
    color: white;
}
  .request-title {
    font-size: 12px;
    font-weight: 600;
    color: white;
  }

  .passage {
    font-size: 14px;
    margin-top: 10px;
    color: white;
  }

  .call-button {
    margin-top: 10px;
  }

  .img-container-p4 {
    margin-bottom: 0px;
    height: 320px;
    width: fit-content;
  }
}
