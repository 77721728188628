.phase6-container {
    display: flex;

    justify-content: center;
}

.css-typing h2 {
    border-right: .15em solid rgb(68, 0, 255);
    font-weight: bolder;
    font-family: "Courier";
    font-size: 2.5rem;
    white-space: nowrap;
    overflow: hidden;
}

.css-typing h2:nth-child(1) {
    -webkit-user-select: none;
    -ms-user-select: none; 
    user-select: none; 
    background: rgb(238, 174, 202);
    background: radial-gradient(circle,#1ca9c9, rgba(148, 187, 233, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 9em;
    -webkit-animation: type 2s steps(40, end);
    animation: type 2s steps(40, end);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  
}

.css-typing h2:nth-child(2) {
    -webkit-user-select: none;
    -ms-user-select: none; 
    user-select: none; 
    width: 15em;
    opacity: 0;
    -webkit-animation: type2 2s steps(40, end);
    animation: type2 2s steps(40, end);
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.css-typing h2:nth-child(3) {
    -webkit-user-select: none;
    -ms-user-select: none; 
    user-select: none; 
    width: 8em;
    opacity: 0;
    -webkit-animation: type3 5s steps(20, end), blink .5s step-end infinite alternate;
    animation: type3 5s steps(20, end), blink .5s step-end infinite alternate;
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes type {
    0% {
        width: 0;
    }

    99.9% {
        border-right: .15em solid rgb(63, 89, 206);
    }

    100% {
        border: none;
    }
}

@-webkit-keyframes type {
    0% {
        width: 0;
    }

    99.9% {
        border-right: .15em solid rgb(23, 223, 47);
    }

    100% {
        border: none;
    }
}

@keyframes type2 {
    0% {
        width: 0;
    }

    1% {
        opacity: 1;
    }

    99.9% {
        border-right: .15em solid rgb(207, 6, 174);
    }

    100% {
        opacity: 1;
        border: none;
    }
}

@-webkit-keyframes type2 {
    0% {
        width: 0;
    }

    1% {
        opacity: 1;
    }

    99.9% {
        border-right: .15em solid rgb(255, 0, 136);
    }

    100% {
        opacity: 1;
        border: none;
    }
}

@keyframes type3 {
    0% {
        width: 0;
    }

    1% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes type3 {
    0% {
        width: 0;
    }

    1% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}

@-webkit-keyframes blink {
    50% {
        border-color: tranparent;
    }
}

.cat-item {
    margin-top: 2rem;
    margin-bottom: 3rem;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

    &::-webkit-scrollbar {
        display: none;
    }


}



.tags_container {
    display: flex;
    /* justify-content: center; */
    margin-top: 1rem;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    /* width: 100%; */
    &::-webkit-scrollbar {
        display: none;
    }
}

.tags_inner_container {
    margin-right: 5rem;
}




.tags_element {
    position: relative;

    flex-direction: row;
    margin-right: 10px;
    margin-top: 1rem;

}


.name {
    align-items: center;
    font-family: "Jersey 20", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
    text-align: center;

}


.tags_element img {
    border-radius: 50%;
    height: 12rem;
    width: 12rem;
    margin-right: 2rem;
    transition: transform 0.3s, box-shadow 0.3s;
    margin-bottom: 2rem;
    margin-left: 1rem;

}

.tags_element img:hover {
    transform: scale(1.05);

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    filter: brightness(0.5);
}

.tags_element:hover::after {
    /* Display the title attribute content as the title */
    position: absolute;
    bottom: -1.5rem;
    /* Adjust the position of the title */
    left: 0;
    width: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    /* Background color for the title */
    color: #fff;
    /* Text color for the title */
    padding: 0.5rem;
    border-radius: 0.5rem;
}









.gm-btn {
    background: none;
    border: none;
    padding: 5px 15px;
    font-size: 16px;
    line-height: 24px;
    width: 400;
    transition: 0.3s;
    cursor: pointer;
    position: relative;
    outline: none;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-family: "Jersey 20", sans-serif;
    font-weight: 400;
    font-style: normal;

}


.gb-cutter::before {
    content: "";
    position: absolute;
    z-index: -1;
    transition: 0.5s;
}

/* cutter */

.gb-cutter {
    border: 2px solid;
    border-radius: 5px;
    box-shadow: 3px 3px;
    font-size: 1.125rem;
    font-weight: 700;
    overflow: hidden;
    /* color: var(--gb-51); */
}

.gb-cutter::before {
    top: 100%;
    left: 0;
    width: 150%;
    aspect-ratio: 1;
    background: black;
    transform: translate(50%, 0%) rotateZ(-45deg);
}

.gb-cutter:hover::before {
    transform: translate(-20%, -60%) rotateZ(-45deg);
}

.gb-cutter:hover {
    box-shadow: 0 0 0;
    color: #eee;
}



.s-button{
    justify-self: center;
    margin-top: 40px;
    margin-bottom: 40px;
    border-radius: 50%;
    height: 40vh;
    width: 40vh;
    border: none;
    background-size: cover;
    font-size: 40px;
    color: aliceblue;
    font-weight: 600;
    transition: transform 0.3s, box-shadow 0.3s;
    /* filter: brightness(0.5); */
    background-size: cover;
    position: relative;

}





.s-button:hover{
    transform: scale(1.05);

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    
}









@media (max-width: 426px) {
    .tags_inner_container {
        margin-right: 2rem;
        margin-left: 6px;
    }

    .tags_element img {

        height: 8rem;
        width: 8rem;
        margin-right: 1rem;


    }


   .css-typing h2{
    font-size: 1.5rem;
   }
}

@media (max-width: 321px) {
    .tags_inner_container {
        margin-right: 1rem;
    }



}