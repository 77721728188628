:root {
    /* --background: #9c88ff; */
    --navbar-width: 256px;
    --navbar-width-min: 80px;
    --navbar-dark-primary: #18283b;
    --navbar-dark-secondary: #2c3e50;
    --navbar-light-primary: #f5f6fa;
    --navbar-light-secondary: #feffff;
  }
  
  html, body {
    margin: 0;
    background: var(--background);
  }


  /* Adjust icon size and position */
.AdminNav-nav-button .AdminNav-nav-button-icon {
    font-size: 1.5rem; /* Adjust icon size as needed */
    margin-right: 8px; /* Adjust icon margin as needed */
  }
  
  /* Hide icons when collapsed */
  
  
  
  .AdminNav-nav-toggle:checked ~ .AdminNav-nav-header {
    width: calc(var(--navbar-width-min) - 16px);
  }
  
  .AdminNav-nav-toggle:checked ~ .AdminNav-nav-content, 
  .AdminNav-nav-toggle:checked ~ .AdminNav-nav-footer {
    width: var(--navbar-width-min);
  }
  
  .AdminNav-nav-toggle:checked ~ .AdminNav-nav-header .AdminNav-nav-title {
    opacity: 0;
    pointer-events: none;
    transition: opacity .1s;
  }
  
  .AdminNav-nav-toggle:checked ~ .AdminNav-nav-header .AdminNav-nav-toggle-label {
    left: calc(50% - 8px);
    transform: translate(-50%);
  }
  
  .AdminNav-nav-toggle:checked ~ .AdminNav-nav-header .AdminNav-nav-toggle-burger {
    background: var(--navbar-light-primary);
  }
  
  .AdminNav-nav-toggle:checked ~ .AdminNav-nav-header .AdminNav-nav-toggle-burger::before,
  .AdminNav-nav-toggle:checked ~ .AdminNav-nav-header .AdminNav-nav-toggle-burger::after {
    width: 16px;
    background: var(--navbar-light-secondary);
    transform: translate(0, 0) rotate(0deg);
  }
  
  .AdminNav-nav-toggle:checked ~ .AdminNav-nav-content .AdminNav-nav-button span {
    opacity: 0;
    transition: opacity .1s;
  }
  
  .AdminNav-nav-toggle:checked ~ .AdminNav-nav-content .AdminNav-nav-button .fas {
    min-width: calc(100% - 16px);
  }
  
  .AdminNav-nav-toggle:checked ~ .AdminNav-nav-footer .AdminNav-nav-footer-avatar {
    margin-left: 0;
    left: 50%;
    transform: translate(-50%);
  }
  
  .AdminNav-nav-toggle:checked ~ .AdminNav-nav-footer .AdminNav-nav-footer-titlebox,
  .AdminNav-nav-toggle:checked ~ .AdminNav-nav-footer .AdminNav-nav-footer-toggle-label {
    opacity: 0;
    transition: opacity .1s;
    pointer-events: none;
  }
  
  .AdminNav-nav-bar {
    position: fixed;
    left: 1vw;
    top: 1vw;
    height: calc(100% - 2vw);
    background: var(--navbar-dark-primary);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    color: var(--navbar-light-primary);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    overflow: hidden;
    user-select: none;
  }
  
  .AdminNav-nav-bar hr {
    margin: 0;
    position: relative;
    left: 16px;
    width: calc(100% - 32px);
    border: none;
    border-top: solid 1px var(--navbar-dark-secondary);
  }
  
  .AdminNav-nav-bar a {
    color: inherit;
    text-decoration: inherit;
  }
  
  .AdminNav-nav-bar input[type="checkbox"] {
    display: none;
  }
  
  .AdminNav-nav-header {
    position: relative;
    width: var(--navbar-width);
    left: 16px;
    width: calc(var(--navbar-width) - 16px);
    min-height: 80px;
    background: var(--navbar-dark-primary);
    border-radius: 16px;
    z-index: 2;
    display: flex;
    align-items: center;
    transition: width .2s;
  }
  
  .AdminNav-nav-header hr {
    position: absolute;
    bottom: 0;
  }
  
  .AdminNav-nav-title {
    font-size: 1.5rem;
    transition: opacity 1s;
  }
  
  .AdminNav-nav-toggle-label {
    position: absolute;
    right: 0;
    width: 3rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .AdminNav-nav-toggle-burger {
    position: relative;
    width: 16px;
    height: 2px;
    background: var(--navbar-dark-primary);
    border-radius: 99px;
    transition: background .2s;
  }
  
  .AdminNav-nav-toggle-burger::before,
  .AdminNav-nav-toggle-burger::after {
    content: '';
    position: absolute;
    top: -6px;
    width: 10px;
    height: 2px;
    background: var(--navbar-light-primary);
    border-radius: 99px;
    transform: translate(2px, 8px) rotate(30deg);
    transition: .2s;
  }
  
  .AdminNav-nav-toggle-burger::after {
    top: 6px;
    transform: translate(2px, -8px) rotate(-30deg);
  }
  
  .AdminNav-nav-content {
    margin: -16px 0;
    padding: 16px 0;
    position: relative;
    flex: 1;
    width: var(--navbar-width);
    background: var(--navbar-dark-primary);
    box-shadow: 0 0 0 16px var(--navbar-dark-primary);
    direction: rtl;
    overflow-x: hidden;
    transition: width .2s;
  }
  
  .AdminNav-nav-content::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  .AdminNav-nav-content::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: #D62929;
  }
  
  .AdminNav-nav-content::-webkit-scrollbar-button {
    height: 16px;
  }
  
  .AdminNav-nav-content-highlight {
    position: absolute;
    left: 16px;
    top: -70px; /* Updated to match the initial hidden position */
    width: calc(100% - 16px);
    height: 54px;
    background: var(--background);
    background-attachment: fixed;
    border-radius: 16px 0 0 16px;
    transition: top .2s;
  }
  
  .AdminNav-nav-content-highlight::before,
  .AdminNav-nav-content-highlight::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 100%;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    box-shadow: 16px 16px var(--background);
  }
  
  .AdminNav-nav-content-highlight::after {
    top: 100%;
    box-shadow: 16px -16px var(--background);
  }
  
  .AdminNav-nav-button {
    position: relative;
    margin-left: 16px;
    height: 54px;
    display: flex;
    align-items: center;
    color: var(--navbar-light-secondary);
    direction: ltr;
    cursor: pointer;
    z-index: 1;
    transition: color .2s;
  }
  
  .AdminNav-nav-button span {
    transition: opacity 1s;
  }
  
  .AdminNav-nav-button .fas {
    transition: min-width .2s;
  }
  
  .AdminNav-nav-button:hover {
    color: rgb(206, 140, 40);
  }
  
  .AdminNav-nav-button:hover ~ .AdminNav-nav-content-highlight {
    top: calc(100% - 70px); /* Updated to properly transition highlight position */
  }
  
  .AdminNav-nav-bar .fas {
    min-width: 3rem;
    text-align: center;
  }
  
  .AdminNav-nav-footer {
    position: relative;
    width: var(--navbar-width);
    height: 54px;
    background: var(--navbar-dark-secondary);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    z-index: 2;
    transition: width .2s, height .2s;
  }
  
  .AdminNav-nav-footer-heading {
    position: relative;
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
  }
  
  .AdminNav-nav-footer-avatar {
    position: relative;
    margin: 11px 0 11px 16px;
    left: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    transform: translate(0);
    transition: .2s;
  }
  
  .AdminNav-nav-footer-avatar img {
    height: 100%;
  }
  
  .AdminNav-nav-footer-titlebox {
    position: relative;
    margin-left: 16px;
    width: 10px;
    display: flex;
    flex-direction: column;
    transition: opacity 1s;
  }
  
  .AdminNav-nav-footer-subtitle {
    color: var(--navbar-light-secondary);
    font-size: .6rem;
  }
  
  .AdminNav-nav-toggle:not(:checked) ~ .AdminNav-nav-footer-toggle:checked + .AdminNav-nav-footer {
    height: 30%;
    min-height: 54px;
  }
  
  .AdminNav-nav-footer-toggle-label {
    position: absolute;
    right: 0;
    width: 3rem;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: transform .2s, opacity .2s;
  }
  
  .AdminNav-nav-footer-content {
    margin: 0 16px 16px 16px;
    border-top: solid 1px var(--navbar-light-secondary);
    padding: 16px 0;
    color: var(--navbar-light-secondary);
    font-size: .8rem;
    overflow: auto;
  }
  
  .AdminNav-nav-footer-content::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  .AdminNav-nav-footer-content::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: #D62929;
  }
  