.inner-container {
  display: flex;
  flex-wrap: wrap; /* Allow flex items to wrap to the next line */
  justify-content: space-between; /* Align items to the start of the container */
  align-items: flex-start; /* Align items to the start of the container */
  /* Add padding from the top */
}


.blank-container{
  height: 90px;
  background-color: white;
}
.first-heading {
  margin-top: 3%;
  padding-top: 1rem;
}

.w-100 {
  width: 100% !important;
  height: 32rem;
  border-radius: 0px 0px 0px 0px;
}

.content {
  flex: 1;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 20px;
}

.container {
  background-color: white;
 
}

h5 {
  color: #8a8a8a;
  padding: 20px;
}

h6 {
  margin-top: 10px;
  padding-left: 10px;
}

.heading_name {
  padding-left: 0%;
  margin-right: 6%;
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.service-title {
  position: relative;
  text-decoration: none;
  color: #333; /* Set the color of the text */
  transition: border-width 0.4s ease; /* Add transition effect to the border-width */
}

/* Create a line effect */
.service-title::after {
  content: "";
  position: absolute;
  bottom: -2px; /* Position the line below the text */
  left: 0;
  width: 0; /* Initially set the width to 0 */
  height: 2px; /* Set the height of the line */
  background-color: rgb(0, 0, 0); /* Set the color of the line */
  transition: width 0.3s ease; /* Add transition effect to the width */
}

/* Add hover effect to the text */
.service-title:hover::after {
  width: 100%; /* Expand the width of the line on hover */
}

.service-container {
  display: flex;
  flex-wrap: wrap; /* Allow flex items to wrap to the next line */
  justify-content: center; /* Center flex items horizontally */
  cursor: pointer;
}

.service-item {
  flex: 0 0 calc(33.33% - 20px); /* Set flex-basis to 33.33% with margin */
  padding: 30px; /* Adjust margins as needed (optional) */
  text-align: center; /* Center the content inside the card */
  cursor: pointer;
}

.buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.book-now-button {
  border: 2px solid black;
  height: 50px;
  width: 170px;
  border-radius: 20px;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  transition: transform 0.3s, box-shadow 0.3s;
  margin-right: 20px;
}

.input:focus {
  border-color: #3182ce;
}

.explore-now-button {
  border: 2px solid black;
  height: 50px;
  width: 170px;
  border-radius: 20px;
  color: white;
  background-color: black;
  transition: transform 0.3s, box-shadow 0.3s;
}

.book-now-button:hover,
.explore-now-button:hover {
  transform: scale(1.05); /* Increase size by 5% */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add shadow effect */
}

.container-card {
  margin-bottom: 70px;
  margin-left: 30px;
  /* padding-left: 20px; */
  height: 100px;
  width: 150px;
  cursor: pointer;
}

.container-card img {
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  height: 100px;
  width: 150px;
}

.row {
  display: flex;
  /* Enable flexbox layout for row */
  /* flex-wrap: wrap; */
  /* Allow elements to wrap on smaller screens */
}

.container {
  flex: 1;
  /* Make container take up remaining space in the row */
}

.image-container {
  flex: 1; /* Make this div take up remaining space */
  display: flex; /* Use flexbox to ensure image is aligned correctly */
  justify-content: flex-end; /* Align the image to the right */
  margin-left: 10px;
  @media (max-width:767px) {
    display: none;
  }
}

.image-container img {
  width: 100%;
  height: 100%;
  margin-top: -64px;
  /* object-fit: cover; Make sure the image covers the container */
}

@media screen and (max-width: 1400px) {
  /* Target Laptop-sized screens */
  .container-card {
    margin-left: -5px;
  }

  .container-card img {
    height: 90px;
    width: 130px;
  }
}

@media screen and (max-width: 1200px) {
  /* Target Laptop-sized screens */
  .container-card {
    margin-left: -6px;
  }

  .container-card img {
    height: 90px;
    width: 130px;
  }
}

@media screen and (min-width: 1025px) {
  /* Target desktop-sized screens */

  .content {
    width: 45%;
    /* Set width to 80% of the viewport */
    height: 510px;
    padding: 1rem;
    /* Apply default padding (adjust as needed) */
    margin-top: 25px;
  }
  .image-container {
    width: 45%; /* Same width as inner container */
    margin-top: 25px;
    padding: 1rem;
    margin-left: 10px; /* Add some margin to separate it from the inner container */
  }
  .image-container img {
    width: 100%;
    height: 100%;
    margin-top: -140px;
    /* object-fit: cover; Make sure the image covers the container */
  }
}

@media screen and (max-width: 1025px) {
  /* Target Laptop-sized screens */
  .content {
    width: 45%;
    /* Set width to 80% of the viewport */
    height: 430px;
    padding: 1rem;
    /* Apply default padding (adjust as needed) */
    margin-top: 25px;
  }
  .image-container {
    width: 45%; /* Same width as inner container */
    margin-top: 25px;
    padding: 1rem;
    margin-left: 10px; /* Add some margin to separate it from the inner container */
  }
  .image-container img {
    width: 120%;
    height: 120%;
    margin-top: -60px;
    /* object-fit: cover; Make sure the image covers the container */
  }

  .container-card {
    margin-bottom: 70px;
    margin-left: 30px;
    /* padding-left: 20px; */
    height: 60px;
    width: 100px;
  }

  .container-card img {
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    height: 60px;
    width: 100px;
  }

  .service-title {
    font-size: 14px;
  }
}

@media screen and (max-width:992px) {
  .container-card {
    margin-right: 1px;
    margin-left: 0px;
  }

  .container-card img {
    width: 80px;
  }
}

@media screen and (min-width: 992px) {
  /* Target desktop-sized screens */
  .container-card {
    margin-right: 10px;
  }
}





@media screen and (max-width: 769px) {
  /* Target Tablet-sized screens */
  .content {
    width: 50%;
    /* Set width to 80% of the viewport */
    height: 380px;
    padding: 1rem;
    /* Apply default padding (adjust as needed) */
    margin-top: 25px;
  }
  .image-container {
    position: relative;
    width: 45%; /* Same width as inner container */
    padding: 0rem;
    margin-top: 135px;
    margin-left: 0px; /* Add some margin to separate it from the inner container */
  }
  .image-container img {
    width: 125%;
    height: 125%;
    margin-top: -90px;
    /* object-fit: cover; Make sure the image covers the container */
  }

  .container-card {
    margin-bottom: 40px;
    margin-left: -2px;
    /* padding-left: 20px; */
    height: 60px;
    width: 100px;
  }

  .container-card img {
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    height: 50px;
    width: 80px;
  }

  .service-title {
    font-size: 12px;
  }

  .book-now-button,
  .explore-now-button {
    padding: 6px 10px;
    font-size: 16px;
  }
}

@media screen and (max-width: 432px) {
  
  .inner-container {
    padding-top: 5px;
  }
  /* Target Mobile-L-sized screens */
  .first-heading {
    margin-top: 3%;
    font-weight: 600;    /* font-size: 20px; */
}
  .header_of_what_are_you_looking{
    font-size: 16px;
  }
  .content {
    width: 45%;
    /* Set width to 80% of the viewport */
    height: 420px;
    padding: 1rem;
    /* Apply default padding (adjust as needed) */
    margin-top: 25px;
  }
  .image-container {
    display: none;
  }
  h5 {
    font-size: 16px;
  }

  .w-100 {
    width: 100% !important;
    height: 16rem;
    padding: 0%;
    border-radius: 0px 0px 0px 0px;
  }

  .service-item {
    margin: 10px; /* Adjust margins as needed (optional) */
    text-align: center; /* Set flex-basis to 50% for smaller screens */
  }

  .container-card {
    margin-bottom: 60px;
    margin-left: 0.1rem;
    /* padding-left: 20px; */
    height: 70px;
    width: 110px;
  }

  .container-card img {
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    height: 4rem;
    width: 6rem;
  }

  .service-title {
    font-size: 12px;
  }

  .book-now-button,
  .explore-now-button {
    height: 2.5rem;
    font-size: 14px;
    width: 8rem;
  }


  .blank-container{
    height: 9rem;
  
  }
}


@media screen and (max-width: 401px) {
  
  
  
  

  .container-card {
    margin-bottom: 3rem;
    margin-left: 0.2rem;
    /* padding-left: 20px; */
    height: 70px;
    width: 110px;
  }

 

  

  .book-now-button,
  .explore-now-button {
    height: 2.5rem;
    font-size: 14px;
    width: 8rem;
  }


  
}

@media screen and (max-width: 396px) {
  .container-card {
    margin-left: 0rem;
    /* padding-left: 20px; */   
  }

}

@media screen and (max-width: 392px) {
  .container-card {
    margin-right: 0rem;
    /* padding-left: 20px; */   
  }

}

@media screen and (max-width: 386px) {
  .container-card {
    margin-left: -0.1rem;
    /* padding-left: 20px; */   
  }

}

@media screen and (max-width: 381px) {
  .container-card {
    margin-left: -0.2rem;
    /* padding-left: 20px; */   
  }

}

@media screen and (max-width: 376px) {
  /* Target mobile-m portrait screens */
  .first-heading {
    margin-top: 3%;
    font-size: 16px;
  }
  .content {
    width: 100%;
    /* Set width to 100% for full width on mobile */
    height: auto;
    padding: 0.5rem;
    /* Adjust padding for mobile layout */
    margin-top: 20px;
  }
  .image-container {
    display: none;
    /* Hide image on mobile screens */
  }

  h5 {
    font-size: 15px;
  }

  .container-card {
    margin-bottom: 60px;
    margin-left: 25px;
    height: 75px;
    width: 130px;
  }

  .container-card img {
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    height: 75px;
    width: 130px;
  }

  .service-title {
    font-size: 12px;
  }

  .book-now-button,
  .explore-now-button {
    padding: 6px 10px;
    font-size: 16px;
  }
}

@media screen and (max-width: 321px) {
  /* Target small mobile -sized screens */
  .content {
    width: 45%;
    /* Set width to 80% of the viewport */
    height: 450px;
    padding: 1rem;
    /* Apply default padding (adjust as needed) */
    margin-top: 25px;
  }
  .image-container {
    display: none; /* Add some margin to separate it from the inner container */
  }

  h5 {
    font-size: small;
  }

  .container-card {
    margin-bottom: 40px;
    margin-left: 14px;
    height: 64px;
    width: 100px;
  }

  .container-card img {
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    height: 64px;
    width: 100px;
  }

  .service-title {
    font-size: 10px;
  }

  .book-now-button,
  .explore-now-button {
    padding: 3px 6px;
    font-size: 16px;
  }
}
