.containerAlter {
    position: relative;
    width: auto;
    padding: 20px;
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;  /* Prevents vertical scrollbar from appearing */
    -webkit-overflow-scrolling: touch; /* Enables smooth scrolling on touch devices */
}

.Shope-cat{
    margin-top: 2.5rem;
    margin-left: 2rem;
    font-weight: 600;
}

.containerAlter::-webkit-scrollbar {
    display: none; /* Hides the scrollbar */
}

.containerAlter .cardAlter {
    flex: 0 0 auto; 
    position: relative;
    width: 14rem;
    height: 18rem;
    background: #fff;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.5s;
    
    /* @media (max-width: 769px) {
        width: 260px;
        height: 320px;
    } */
}

.containerAlter:hover .cardAlter {
    transform: scale(0.95);
}

.containerAlter .cardAlter:hover {
    transform: scale(1.02);
}

.containerAlter .cardAlter .circleAlter {
    position: relative;
    width: 100%;
    height: 100%;
    background: #000;
    clip-path: circle(20% at 0 0);
    text-align: center;
    transition: 0.5s;
}

.containerAlter .cardAlter:hover .circleAlter {
    clip-path: circle(140% at 0 0);
}

.containerAlter .cardAlter .contentAlter {
    position: absolute;
    bottom: 20px;
    padding-left: 1.5rem;
    text-align: center;
    /* flex-wrap: wrap; */
    @media (max-width: 769px) {
        padding: 15px;
        bottom: 1px;
    }
}

.containerAlter .cardAlter .contentAlter .imgAlter {
    width: 11rem;
    height: 7rem;
    margin-bottom: 1rem;
    
    @media (max-width: 769px) {
        width: 12rem;
    }
}

.containerAlter .cardAlter .contentAlter .pAlter {
    color: #666;
    transition: 0.5s;
    
}

.containerAlter .cardAlter .contentAlter .buttonAlter {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    margin-top: 20px;
    background: #000;
    color: #fff;
    border-radius: 10px;
    outline: none;
    border: none;
    cursor: pointer;
    width: 10rem;
}

.containerAlter .cardAlter:hover .contentAlter .buttonAlter {
    background: #fff !important;
    color: #000 !important;
}

.containerAlter .cardAlter:hover .contentAlter .pAlter {
    color: #fff;
}


.containerAlter .cardAlter .circleAlter,
.containerAlter .cardAlter .contentAlter .buttonAlter {
    background: #1069a4;
    transition: 0.5s;
}

/* color of cards */

/* .containerAlter .cardAlter:nth-child(1) .circleAlter,




.containerAlter .cardAlter:nth-child(3) .circleAlter,
.containerAlter .cardAlter:nth-child(3) .contentAlter .buttonAlter {
    background: #341f97;
    transition: 0.5s;
}
.containerAlter .cardAlter:nth-child(4) .circleAlter,
.containerAlter .cardAlter:nth-child(4) .contentAlter .buttonAlter {
    background: #156de7;
    transition: 0.5s;
}
.containerAlter .cardAlter:nth-child(5) .circleAlter,
.containerAlter .cardAlter:nth-child(5) .contentAlter .buttonAlter {
    background: #e6a014;
    transition: 0.5s;
}

.containerAlter .cardAlter:nth-child(6) .circleAlter,
.containerAlter .cardAlter:nth-child(6) .contentAlter .buttonAlter {
    background: #14e6e6;
    transition: 0.5s;
} */

/* Responsive styles for smaller screens */
@media (max-width: 432px) {
    .containerAlter {
        padding: 10px; /* Reduce container padding */
    }

    .containerAlter .cardAlter {
        width: 12rem; /* Reduce card width */
        height: 16rem; /* Reduce card height */
        margin: 10px; /* Reduce margin between cards */
    }
  
    .containerAlter .cardAlter .contentAlter {
        padding: 1rem; /* Reduce content padding */
    }

    .containerAlter .cardAlter .contentAlter .imgAlter {
        width: 10rem; /* Reduce image width */
        object-fit: contain;
        margin-bottom: 0.5rem; /* Reduce margin below the image */
    }
  
    .containerAlter .cardAlter .contentAlter .pAlter {
        font-size: 12px; /* Reduce text size */
    }
  
    .containerAlter .cardAlter .contentAlter .buttonAlter {
        padding: 5px 10px; /* Reduce button padding */
        font-size: 12px; /* Reduce button text size */
        width: 10rem;
    }
}
