.admin-panel-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.content-container {
    flex: 1;

    padding: 20px;
     width: 78%;
     margin-left: 20%;
    
    overflow-y: auto;
}

.page-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

.table-container {
    overflow-x: auto;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.user-table th, .user-table td {
    padding: 12px 16px;
    border-bottom: 1px solid #eee;
    white-space: nowrap;
}

.user-table th {
    background-color: #f0f0f0;
    color: #333;
    font-weight: bold;
    text-align: left;
}

.user-table tbody tr:nth-child(even) {
    background-color: #fafafa;
}

.user-table tbody tr:hover {
    background-color: #f5f5f5;
}

.phone-number-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.copy-button {
    background: #007bff;
    border: none;
    color: white;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.copy-button:hover {
    background-color: #0056b3;
}

.copy-button svg {
    /* margin-right: 4px; */
    font-size: 16px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .content-container {
        padding: 10px;
    }

    .page-title {
        font-size: 20px;
    }

    .user-table th, .user-table td {
        /* padding: 10px; */
        font-size: 12px;
    }

    .copy-button {
        padding: 0px 4px;
        font-size: 14px;
    }

    .copy-button svg {
        /* margin-right: 4px; */
        font-size: 10px;
    }
}