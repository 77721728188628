/* Base styles for the form container */
.RequestAquoteForm-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
}

.RequestAquoteForm-title {
    text-align: center;
    color: #3674d9; /* Updated color */
    font-size: 24px;
    margin-bottom: 10px;
}

.RequestAquoteForm-subtitle {
    text-align: center;
    font-size: 16px;
    color: #757575;
    margin-bottom: 20px;
}

.RequestAquoteForm-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.RequestAquoteForm-label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

.RequestAquoteForm-input,
.RequestAquoteForm-textarea {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
}

.RequestAquoteForm-input:focus,
.RequestAquoteForm-textarea:focus {
    border-color: #1466ae;
    outline: none;
}

.RequestAquoteForm-button {
    padding: 15px;
    background-color: #335eea; /* Updated color */
    border: none;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.RequestAquoteForm-button:hover {
    background-color: #ff8a50; /* Updated color */
}

/* Responsive Design */
@media (max-width: 600px) {
    .RequestAquoteForm-container {
        padding: 15px;
    }

    .RequestAquoteForm-title {
        font-size: 22px;
    }

    .RequestAquoteForm-subtitle {
        font-size: 14px;
    }

    .RequestAquoteForm-input,
    .RequestAquoteForm-textarea {
        font-size: 14px;
    }

    .RequestAquoteForm-button {
        font-size: 16px;
    }
}
