.popup-detail-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-heading{
    
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.popup-heading-h3{
    font-weight: 600;
}

.popup-detail-form {
    background:whitesmoke;
    padding: 1rem;
    border-radius: 5px;
    width: 22rem;
    position: relative;
}

.popup-detail-form img{
    height: 2.8rem;
    width: 2.8rem;
}

.popup-detail-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.popup-detail-form-group {
    margin-bottom: 15px;
}




.popup-detail-form-group input,
.popup-detail-form-group textarea,
.popup-detail-form-group select {
    background-color: white;
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-color 0.3s;
    appearance: none; /* Remove default appearance */
    -webkit-appearance: none; /* Remove default appearance in Safari */
    -moz-appearance: none; /* Remove default appearance in Firefox */
}

.popup-detail-form-group input:focus,
.popup-detail-form-group textarea:focus,
.popup-detail-form-group select:focus {
    border-color: #007BFF;
    outline: none;
}


.popup-detail-submit-button {
    width: 100%;
    background: #0b243e;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
}

.popup-detail-submit-button:hover {
    background: #000000;
}


.custom-select-container {
    position: relative;
}

