.bottom_bar_container {
    background: rgb(254, 254, 254);
    bottom: 0;
    position: fixed;
    z-index: 7;
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    display: none;
    box-shadow: 3px 1px 140px -21px rgba(0,0,0,0.75);
}

.drawer {
    z-index: 6;
    height: 32rem;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.982);
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px 20px 0 0;
    padding: 1rem;
    box-sizing: border-box;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
}

.drawer.open {
    transform: translateY(0);
}

.drawer-header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
}

.close-icon {
    font-size: 1.5rem;
    cursor: pointer;
}

.drawer-content ul {
    list-style-type: none;
    padding: 0.5rem;
    margin-bottom: 1rem;
    height: 25rem;
    overflow-y: scroll;
    scrollbar-width: none; /* For Firefox */
}

.drawer-content ul::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

.drawer-content ul li {
    padding: 1rem 0;
    border-bottom: 1px solid #e0e0e0;
    color: inherit;
    text-decoration: none;
}

.drawer-content ul li:last-child {
    border-bottom: none;
}

.drawer-content ul li a {
    color: inherit;
    text-decoration: none;
}

.drawer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 5;
}

.active_bottom_link {
    color: #000000;
}

.bottom_bar_container ul {
    display: flex;
    justify-content: space-around;
    font-size: 22px;
    font-weight: 600;
    flex-direction: row;
}

.bottom_bar_container ul li {
    margin-left: 2rem;
    padding-top: 1rem;
}

.bottom_bar_container ul li a {
    color: inherit;
    text-decoration: none;
}

@media (max-width: 431px) {
    .bottom_bar_container {
        display: block;
    }
}
