/* Specific styles for ProductDetails component */
.quantity-selector {
  gap: 1rem;
  /* justify-content: space-between; */
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}

.quantity-selector label {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.quantity-selector input {
  width: 60px;
  height: 40px;
  padding: 5px 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.quantity-selector input:focus {
  outline: none;
  border-color: #007bff;
}

.quantity-selector input[type='number']::-webkit-inner-spin-button,
.quantity-selector input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quantity-selector input[type='number'] {
  -moz-appearance: textfield;
}

.quantity-product-button {
  margin-top: 10px;
}

.gb-shutter::before,
.gb-shutter::after

{
    
    content: "";
    position: absolute;
    z-index: -1;
    transition: 0.5s;
}


.gb-shutter{
  padding: 0.4rem;
  border: 2px solid black;
  border-radius: 5px;
  box-shadow: 3px 3px 0 black;
  font-size: 1.125rem;
  font-weight: 700;
  overflow: hidden;
  color: black;
}
.gb-shutter::after, .gb-shutter::before{
  top: 0;
  left: 0;
  width: 130%;
  aspect-ratio: 1;
  /* background: black; */
}
.gb-shutter::after{
  transform: translate(-100%,-80%) rotate(30deg);
}
.gb-shutter::before{
  transform: translate(100%,30%) rotate(30deg);
}
.gb-shutter:hover::after{
  transform: translate(-50%,-50%) rotate(30deg);
}
.gb-shutter:hover::before{
  transform: translate(30%,-10%) rotate(30deg);
}
.gb-shutter:hover{
  background-color: black;
  color: #ffffff;
  box-shadow: 0 0 0;
}







.custom-small-container {
  /* Styles for small-container */
  margin: auto;
  padding-left: 25px;
  padding-right: 25px;
}

.custom-row {
  /* Styles for row */
  display: flex;
  /* align-items: center; */
  flex-wrap: wrap;
  justify-content: space-around;
}


/* add to cart button  */


.ProductDetailsImage{
  width: 45rem;
  height: 40rem;
  object-fit: cover;

  @media (max-width:1025px) {
    width: 30rem;
    height: 28rem;
  };

  @media (max-width:431px) {
    height: 20rem;
    width: 24rem;
  }

  @media (max-width:376px) {
    width: 20rem;
  }

  @media (max-width:321px) {
    width: 17rem;
    height: 15rem;
  }
}





.Related-text-container{
  margin-left: 3rem;
  margin-right:4rem;
}

/* .similar-product-container{
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  
  height: 50rem;
  &::-webkit-scrollbar {
    display: none;
  }
} */

.related-item-text{
  margin-top: 1rem;

  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.custom-col-2 {
  /* Styles for col-2 */
  flex-basis: 50%;
  min-width: 300px;
}

.custom-small-img-row {
  /* Styles for small-img-row */
  margin-top: 2%;
  margin-bottom: 5%;
  display: flex;
  justify-content: space-between;
}

.custom-small-img-col {
  /* Styles for small-img-col */
  flex-basis: 24%;
  cursor: pointer;
}

.custom-small-img {
  /* Styles for small-img */
}



.custom-fas {
  /* Styles for fas */
}

.column-xs-12 {
  width: 100%;
}

.column-md-5 {
  flex-basis: 41.66667%;
  max-width: 41.66667%;
  margin-top: 1rem;
}

.product-description {
  border-top: 0.0625rem solid #b8b5b5;
  margin: 2rem 0;
  padding: 1rem 0 0 0;
  font-size: 0.8rem;
}



.quantity-button{
  display: flex;
}


.description-title{
  margin-top: 1rem;
}
@media (max-width: 768px) {
  .column-md-5 {
    flex-basis: 100%;
    max-width: 100%;
    margin-top: 1rem;
  }

  .custom-col-2 {
    
    margin-bottom: 2rem;
  }

  .Related-text{
    font-size: 24px;
  }

  .view-more-text{
    font-size: 12px;
  }

  .Related-text-container {
    margin-left: 1.5rem;
    margin-right: 2rem;
}
}





@media (max-width: 431px) {
  .custom-col-2 {
    flex-basis: 100%;
    max-width: 100%;
    /* margin-bottom: 2rem; */
  }

  .Related-text{
    font-size: 16px;
  }

  .view-more-text{
    font-size: 10px;
    margin-top: 4%;
  }

  .description{
    font-size: 0.7rem;
  }

 
  

  
}
