/* Container for the entire FAQ section */
.faq-container {
    max-width: 64rem;
    margin: 3rem auto;
    padding: 2rem;
    background-color: #f9fafb;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Title of the FAQ section */
  .faq-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
    color: #333;
  }
  
  /* Container for the menu */
  .faq-menu {
    border-bottom: 2px solid #e5e7eb;
    margin-bottom: 2rem;
  }
  
  /* List within the menu */
  .faq-menu-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  /* List items in the menu */
  .faq-menu-list li {
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }
  
  /* Links within the menu */
  .faq-menu-link {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    font-weight: 600;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    color: #333;
    transition: color 0.3s, border-color 0.3s;
  }
  
  /* Active menu link */
  .faq-menu-link.active {
    background-color: #fff;
    border-color: #1966a5;
  }
  
  /* Hover effect for menu links */
  .faq-menu-link:hover {
    color: #1966a5;
    border-color: #1966a5;
  }
  
  /* Container for FAQ items */
  .faq-items {
    border-top: 2px solid #e5e7eb;
  }
  
  /* Individual FAQ item */
  .faq-item {
    padding: 1rem 0;
    border-bottom: 2px solid #e5e7eb;
    transition: background-color 0.3s;
  }
  
  /* Container for the question and icon */
  .faq-question-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 1rem;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  /* Question text */
  .faq-question {
    font-size: 1.125rem;
    font-weight: 600;
    color: #333;
  }
  
  /* Icon next to the question */
  .faq-icon {
    color: #1966a5;
    font-size: 1.5rem;
    font-weight: bold;
    transition: transform 0.3s;
  }
  
  /* Container for the answer */
  .faq-answer {
    padding: 1rem;
    font-size: 1rem;
    color: #555;
    background-color: #fff;
    border-radius: 4px;
    margin-top: 0.5rem;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  }
  
  /* Hover effect for the question container */
  .faq-question-container:hover {
    background-color: #f3f4f6;
  }
  
  /* Rotate the icon when the answer is visible */
  .faq-item.open .faq-icon {
    transform: rotate(45deg);
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .faq-container {
      padding: 1rem;
    }
  
    .faq-title {
      font-size: 1.5rem;
    }
  
    .faq-menu-link {
      padding: 0.5rem 1rem;
      font-size: 0.875rem;
    }
  
    .faq-question {
      font-size: 1rem;
    }
  
    .faq-icon {
      font-size: 1.25rem;
    }
  
    .faq-answer {
      font-size: 0.875rem;
    }
  }
  
  @media (max-width: 480px) {
    .faq-title {
      font-size: 1.25rem;
    }
  
    .faq-menu-link {
      padding: 0.5rem;
      font-size: 0.75rem;
    }
  
    .faq-question {
      font-size: 0.875rem;
    }
  
    .faq-icon {
      font-size: 1rem;
    }
  
    .faq-answer {
      font-size: 0.75rem;
    }
  }
  