.product-list {
    padding: 20px;
  }
  
  .Categories-product-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .Categories-product-card {
    border: 1px solid #ddd;
    padding: 10px;
    width: 200px;
    text-align: center;
  }
  
  .Categories-product-card img {
    max-width: 100%;
    height: auto;
  }
  
  .Categories-product-card h2 {
    font-size: 16px;
    margin: 10px 0;
  }
  
  .Categories-product-card p {
    margin: 5px 0;
  }
  