.shopping-cart-checkout {
  background-color: white;
    display: flex;
    flex-direction:column;
  }

  .Checkout-row{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  

  .quantity-controls {
    display: flex;
    align-items: center;
  }
  
  .quantity-button {
    
    color: transparent;
    border: none;
    /* border-radius: 4px; */
    cursor: pointer;
    
    
  }
  
  .quantity_checkout {
    font-size: 1rem;
    font-weight: bold;
    /* margin: 0 0.5rem; */
    min-width: 2rem;
    text-align: center;
  }
  
  
  .cart-item-checkout {
    width: 100%;
    padding: 1rem;
    
  }
  

  .quantity-controls {
    display: flex;
    align-items: center;
}

.quantity-button {
    background-color: #ffffff;
    color: #000000;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 1rem;
    cursor: pointer;
    margin-right: 5px;
}

.quantity {
    margin: 0px;
}

.remove-button {
    
    color: #ff0000;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 10px;
}

.remove-button:hover {
    background-color: #f9f9f9;
    color: #000000;
}


  .cart-item-title-checkout {
    font-size: 1.5rem;
    font-weight: bold;
    /* margin-bottom: 0rem; */
    padding-left: 1rem;
    padding-top: 1rem;
  }
  
  .cart-item-details-checkout {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 16px;
  }

  .checkout-product-price{
    margin-bottom: 0rem;
    /* color: #13d43d; */
  }
  
  .cart-item-info-checkout {
    display: flex;
    align-items: center;
  }
  
  .cart-item-image-checkout {
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 16px;
  }
  
  .cart-item-name-checkout {
    font-weight: bold;
  }
  
  .cart-item-price-checkout {
    color: #6c757d;
  }
  
  .order-summary-checkout {
    width: 100%;
    padding: 16px;
  }
  
  .order-summary-title-checkout {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }
  
  .order-summary-details-checkout {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 16px;
    
  }
  
  .order-summary-row-checkout {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  .coupon-code-input-checkoutPage{
    width: 14rem;
    padding: 0.5rem;
  }
  
  .checkout-button-checkoutPage {
    background-color: #000000;
    color: #fff;
    border: none;
    border-radius: 0px;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.checkout-product-items{
  height: 30rem;
  overflow-y: scroll;
  
}
.checkout-product-items::-webkit-scrollbar {
  width: 0;
}

/* Hide scrollbar for Firefox */
.checkout-product-items {
  scrollbar-width: none;
}

.checkout-button-checkoutPage:hover {
    background-color: #13d43d;
}


.coupon-input-checkoutPage {
 
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.apply-button-checkoutPage {
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 1px;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.apply-button-checkoutPage:hover {
  background-color: #5ed10b;
}


  .divider-checkoutPage {
    margin: 1rem 0;
    border: 0;
    border-top: 1px solid #ccc;
}

  .grid-container-checkout {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4rem;
    
  }
  
  @media (min-width: 768px) {
    .grid-container-checkout {
      grid-template-columns: repeat(2, 1fr);
      padding: 2rem;
      
    }

    .grid-container-checkout {
      grid-gap: 2rem;
  }

    .cart-item-image-checkout {
      width: 8rem;
      height: 8rem;
      /* object-fit: cover;
      border-radius: 8px;
      margin-right: 16px; */
    }

    .cart-item-checkout {
      width: 100%;
      padding: 2%;
      
    }

    
  }
  
 